import React from "react";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./database/Nav";
import {useTranslation} from "react-i18next";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {HOME_PAGE_URL} from "./HomePage";

const RequestNewPasswordConfirmationPage: React.FC<RouteComponentProps<{}, {}, {}>> = (props) => {

    const {t} = useTranslation("ReportIncidentConfirmationPage");

    const history = useHistory()

    if (!props.location.state) {
        history.push(HOME_PAGE_URL)
    }

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "RequestNewPasswordConfirmationPage"})
    })

    return (<>
        <Header/>

        <Nav/>

        <p>{t("Vielen Dank.")}</p>

        <Footer/>
    </>);
}

export const REQUEST_NEW_PASSWORD_CONIRMATION_PAGE_URL = "/request_new_password_confirmation"

export default withRouter(RequestNewPasswordConfirmationPage);
