import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {AnimalAgeGroup} from "../../../graphql/type/AnimalAgeGroup";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Form, Formik, Field} from 'formik';
import {ANIMAL_AGE_GROUPS_PAGE_URL} from "./AnimalAgeGroupsPage";
import Nav from "../Nav";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import {useTranslation} from "react-i18next";

interface EditAnimalAgeGroupPageProps {
    id: string
}

const GQ = gql`query EditAnimalAgeGroupPage($id: ID!) {
    getAnimalAgeGroup(id: $id) {
        id
        captionGerman
        captionEnglish
        captionFrench
        enabled
    }
}`;

interface EditAnimalAgeGroupPageRequest {
    id: string;
}

interface EditAnimalAgeGroupsPageResponse {
    getAnimalAgeGroup: AnimalAgeGroup
}

const GQ_CREATE_OR_UPDATE_ANIMAL_AGE_GROUP = gql`
  mutation createOrUpdateAnimalAgeGroup(
      $id: ID,
      $captionGerman: String,
      $captionEnglish: String,
      $captionFrench: String,
      $enabled: Boolean!
  ) {
    createOrUpdateAnimalAgeGroup(
      id: $id,
      captionGerman: $captionGerman,
      captionEnglish: $captionEnglish,
      captionFrench: $captionFrench,
      enabled: $enabled
    ) {
      id
    }
  }
`;

const EditAnimalAgeGroupPage: React.FC<RouteComponentProps<{}, {}, EditAnimalAgeGroupPageProps>> = (props) => {

    const {t} = useTranslation("admin/animalagegroup/EditAnimalAgeGroupPage");

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<EditAnimalAgeGroupsPageResponse, EditAnimalAgeGroupPageRequest>(GQ, {
        variables: {
            id: props.location.state?.id
        },
        skip: props.location.state?.id == null
    });

    const [createOrEditAnimalAgeGroup] = useMutation(GQ_CREATE_OR_UPDATE_ANIMAL_AGE_GROUP, {
        onCompleted: () => {
            history.push(ANIMAL_AGE_GROUPS_PAGE_URL)
        },
        refetchQueries: [
            {
                query: gql`query allAnimalAgeGroups {
                    allAnimalAgeGroups {
                        id
                        caption(language: "de")
                        captionGerman
                        captionEnglish
                        captionFrench
                        enabled
                    }
                }`
            },
            {
                query: gql`query allAnimalAgeGroups {
                    allAnimalAgeGroups {
                        id
                        caption(language: "en")
                        captionGerman
                        captionEnglish
                        captionFrench
                        enabled
                    }
                }`
            },
            {
                query: gql`query allAnimalAgeGroups {
                    allAnimalAgeGroups {
                        id
                        caption(language: "fr")
                        captionGerman
                        captionEnglish
                        captionFrench
                        enabled
                    }
                }`
            }
        ]
    });

    const history = useHistory()

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={ANIMAL_AGE_GROUPS_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Altersgruppe")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        captionGerman: gq.data?.getAnimalAgeGroup?.captionGerman,
                        captionEnglish: gq.data?.getAnimalAgeGroup?.captionEnglish,
                        captionFrench: gq.data?.getAnimalAgeGroup?.captionFrench,
                        enabled: !!gq.data?.getAnimalAgeGroup?.enabled
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        createOrEditAnimalAgeGroup({
                            variables: {
                                id: props.location.state?.id,
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Bezeichnung")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="captionGerman">{t("Deutsch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.captionGerman ? " is-invalid" : "")} id="captionGerman" name="captionGerman" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.captionGerman ? <div className="invalid-feedback">{t(status.captionGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="captionEnglish">{t("Englisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.captionEnglish ? " is-invalid" : "")} id="captionEnglish" name="captionEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.captionEnglish ? <div className="invalid-feedback">{t(status.captionEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="captionFrench">{t("Französisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.captionFrench ? " is-invalid" : "")} id="captionFrench" name="captionFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.captionFrench ? <div className="invalid-feedback">{t(status.captionFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Aktiviert")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <Field type="checkbox" className={"" + (status && status.enabled ? " is-invalid" : "")} id="enabled" name="enabled"/>
                                                <label htmlFor="enabled">Aktiviert</label>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.enabled ? <div className="invalid-feedback">{t(status.enabled)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>Speichern</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>

            <Footer/>
        </>
    );
}

export const EDIT_ANIMAL_AGE_GROUP_PAGE_URL = "/admin/animal_age_groups/animal_age_group/edit"

export default withRouter(EditAnimalAgeGroupPage);
