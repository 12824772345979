import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {AnimalHygieneStatus} from "../../../graphql/type/AnimalHygieneStatus";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {ANIMAL_HYGIENE_STATUS_PAGE_URL} from "./AnimalHygieneStatusPage";
import Nav from "../Nav";
import {useTranslation} from "react-i18next";
import {Form, Formik, Field} from "formik";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import i18n from "i18next";

const GQ = gql`query deleteAnimalHygieneStatusPage($id: ID!, $language: String!) {
    getAnimalHygieneStatus(id: $id) {
        id
        caption(language: $language)
    }
    allAnimalHygieneStatus {
        id
        caption(language: $language)
    }
}`;

interface DeleteAnimalHygieneStatusPageRequest {
    id: string
    language: string
}

interface DeleteAnimalHygieneStatusPageResponse {
    getAnimalHygieneStatus: AnimalHygieneStatus
    allAnimalHygieneStatus: AnimalHygieneStatus[]
}

const GQ_DELETE = gql`mutation deleteAnimalHygieneStatus($id: ID!, $replaceWith: ID) {
    deleteAnimalHygieneStatus(id: $id, replaceWith: $replaceWith)
}`;

interface DeleteAnimalHygieneStatusRequest {
    id: string;
    replaceWith: string | undefined
}

interface DeleteAnimalHygieneStatusResponse {
}

interface DeleteAnimalHygieneStatusPageProps {
    id: string
}

const DeleteAnimalHygieneStatusPage: React.FC<RouteComponentProps<{}, {}, DeleteAnimalHygieneStatusPageProps>> = (props) => {

    const {t} = useTranslation("admin/animalhygienestatus/DeleteAnimalHygieneStatusPage")

    const history = useHistory();

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<DeleteAnimalHygieneStatusPageResponse, DeleteAnimalHygieneStatusPageRequest>(GQ, {
        variables: {
            id: props.location.state.id,
            language: i18n.language
        }
    });

    const [gqDelete] = useMutation<DeleteAnimalHygieneStatusResponse, DeleteAnimalHygieneStatusRequest>(GQ_DELETE, {
        onCompleted: () => {
            history.push(ANIMAL_HYGIENE_STATUS_PAGE_URL);
        },
        refetchQueries: [
            {
                query: gql`query animalHygieneStatusPage {
                    allAnimalHygieneStatus {
                        id
                    }
                }`
            },
            {
                query: gql`query reportIncidentPage {
                    allEnabledAnimalHygieneStatus {
                        id
                    }
                }`
            }
        ]
    });


    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={ANIMAL_HYGIENE_STATUS_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Hygienestatus löschen")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        replaceWith: undefined
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqDelete({
                            variables: {
                                id: props.location.state.id,
                                replaceWith: values.replaceWith
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)
                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <p>Soll der Eintrag <em>{gq.data?.getAnimalHygieneStatus?.caption}</em> wirklich gelöscht werden?</p>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <div className="mb-3">
                                        <label htmlFor="replaceWith">{t("Ersetzen durch")}</label>
                                        <Field as="select" className={"form-select" + (status && status.replaceWith ? " is-invalid" : "")} id="replaceWith" name="replaceWith">
                                            <option value="-1">{t("Bitte wählen")}</option>
                                            {gq.data?.allAnimalHygieneStatus?.filter((i) => i.id !== props.location.state.id)
                                                .sort((i1, i2) => i1.caption!!.localeCompare(i2.caption!!))
                                                .map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                            {status && status.replaceWith ? <div className="invalid-feedback">{t(status.replaceWith)}</div> : <></>}
                                        </Field>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-warning" disabled={isSubmitting}>Löschen</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>

            </div>

            <Footer/>
        </>
    );
}

export const DELETE_ANIMAL_HYGIENE_STATUS_PAGE_URL = "/admin/animal_age_hygiene_status/animal_hygiene_status/delete"

export default withRouter(DeleteAnimalHygieneStatusPage);
