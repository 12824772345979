import React, {Suspense, useEffect, useState} from 'react';
import DefaultRouter from "../pages/DefaultRouter";
import {BrowserRouter} from "react-router-dom";
import {ApolloProvider} from '@apollo/client';
import "./../util/i18n/i18n"
import client from "../graphql/ApolloClient";
import {MatomoProvider} from "@datapunt/matomo-tracker-react";
import matomoResolver from "../util/tracking/matomo";
import {MatomoInstance} from "@datapunt/matomo-tracker-react/lib/types";

function App() {

    const [matomo, setMatomo] = useState<MatomoInstance | undefined>(undefined)

    useEffect(() => {
        async function resolveMatomo() {
            setMatomo(await matomoResolver());
        }
        resolveMatomo();
    }, [])

    return (
        matomo ?
            <MatomoProvider value={matomo!!}>
                <ApolloProvider client={client}>
                    <Suspense fallback={<p>Loading...</p>}>
                        <BrowserRouter>
                            <DefaultRouter/>
                        </BrowserRouter>
                    </Suspense>
                </ApolloProvider>
            </MatomoProvider> :
            <ApolloProvider client={client}>
                <Suspense fallback={<p>Loading...</p>}>
                    <BrowserRouter>
                        <DefaultRouter/>
                    </BrowserRouter>
                </Suspense>
            </ApolloProvider>
    );
}

export default App;
