import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import FAQPage, {FAQ_PAGE_URL} from "./FAQPage";
import ProjectPage, {PROJECT_PAGE_URL} from "./ProjectPage";
import TeamPage, {TEAM_PAGE_URL} from "./TeamPage";
import NewsRouter, {NEWS_ROUTER_URL} from "./news/NewsRouter";

function ProjectRouter() {

    return (
        <Switch>
            <Route exact path={PROJECT_PAGE_URL}><ProjectPage/></Route>
            <Route exact path={TEAM_PAGE_URL}><TeamPage/></Route>
            <Route exact path={FAQ_PAGE_URL}><FAQPage/></Route>
            <Route path={NEWS_ROUTER_URL}><NewsRouter/></Route>
            <Redirect to={PROJECT_PAGE_URL}/>
        </Switch>
    )
}

export const PROJECT_ROUTER_URL = "/project"

export default ProjectRouter;
