import React from "react";
import LanguageSelect from "../LanguageSelect";
import {NavLink} from "react-router-dom";
import {HOME_PAGE_URL} from "../HomePage";
import {Logout} from "../Logout";
import {useTranslation} from "react-i18next";
import {ADMIN_HOME_PAGE_URL} from "./AdminHomePage";

function Nav() {

    const {t} = useTranslation("admin/Nav");

    return (
        <nav id="navigation" className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container flex-row-reverse">
                <LanguageSelect/>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav justify-content-between w-100 pe-md-6">
                        <li className="nav-item">
                            <NavLink to={HOME_PAGE_URL} className="nav-link">{t("Startseite")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={ADMIN_HOME_PAGE_URL} className="nav-link">{t("Administration")}</NavLink>
                        </li>
                        <Logout/>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Nav;