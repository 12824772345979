import {gql, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Link} from "react-router-dom";
import {ADMIN_HOME_PAGE_URL} from "../AdminHomePage";
import {IncidentExperimentResultUsability} from "../../../graphql/type/IncidentExperimentResultUsability";
import {EDIT_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL} from "./EditIncidentExperimentResultUsabilityPage";
import {DELETE_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL} from "./DeleteIncidentExperimentResultUsabilityPage";
import React from "react";
import {useTranslation} from "react-i18next";
import Nav from "../Nav";
import i18n from "i18next";

const GQ = gql`query incidentExperimentResultUsabilitiesPage($language: String!) {
    allIncidentExperimentResultUsabilities {
        id
        caption(language: $language)
        enabled
    }
}`;

interface IncidentExperimentResultUsabilitiesPageRequest {
    language: string
}

interface IncidentExperimentResultUsabilitiesPageResponse {
    allIncidentExperimentResultUsabilities: IncidentExperimentResultUsability[]
}

const IncidentExperimentResultUsabilitiesPage = () => {

    const {t} = useTranslation("admin/incidentexperimentresultusability/IncidentExperimentResultUsabilitiesPage");

    const {loading, error, data} = useQuery<IncidentExperimentResultUsabilitiesPageResponse, IncidentExperimentResultUsabilitiesPageRequest>(GQ, {
        variables: {
            language: i18n.language
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="container mb-5">
                    <Link to={ADMIN_HOME_PAGE_URL}>Zurück</Link>
                </div>

                <div className="container mb-3">
                    <h1>{t("Verwertbarkeiten")}</h1>
                </div>

                <div className="container">
                    <Link to={EDIT_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL}>Neue Verwertbarkeit</Link>
                </div>

                <table className="table">

                    <thead>
                    <tr>
                        <th>Eintrag</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>


                    {
                        // der slice() Aufruf wird benötigt, um das Array zu kopieren, da sonst sortierung nicht möglich ist
                        data?.allIncidentExperimentResultUsabilities?.slice().sort((i1, i2) => i1.caption!!.localeCompare(i2.caption!!))?.map((it) => (<tr key={it.id}>
                            <td>{it.caption}</td>
                            <td><Link to={{pathname: EDIT_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL, state: {id: it.id}}}>Bearbeiten</Link></td>
                            <td><Link to={{pathname: DELETE_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL, state: {id: it.id}}}>Löschen</Link></td>
                        </tr>))}

                    </tbody>

                </table>

            </div>

            <Footer/>
        </>
    );
}

export const INCIDENT_EXPERIMENT_RESULT_USABILITIES_PAGE_URL = "/admin/incident_experiment_result_usabilities"

export default IncidentExperimentResultUsabilitiesPage;
