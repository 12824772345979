import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Incident} from "../../../graphql/type/Incident";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Form, Formik, Field} from 'formik';
import Nav from "../Nav";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import {useTranslation} from "react-i18next";
import {AnimalAgeGroup} from "../../../graphql/type/AnimalAgeGroup";
import {AnimalGender} from "../../../graphql/type/AnimalGender";
import {AnimalHygieneStatus} from "../../../graphql/type/AnimalHygieneStatus";
import {AnimalPregnantStatus} from "../../../graphql/type/AnimalPregnantStatus";
import {AnimalSpecies} from "../../../graphql/type/AnimalSpecies";
import {IncidentContext} from "../../../graphql/type/IncidentContext";
import {IncidentContributionFactor} from "../../../graphql/type/IncidentContributionFactor";
import {IncidentDegreeOfDistress} from "../../../graphql/type/IncidentDegreeOfDistress";
import {IncidentDiscipline} from "../../../graphql/type/IncidentDiscipline";
import {IncidentFrequency} from "../../../graphql/type/IncidentFrequency";
import {IncidentExperimentResultUsability} from "../../../graphql/type/IncidentExperimentResultUsability";
import {INCIDENTS_PAGE_URL} from "./IncidentsPage";
import {ReporterType} from "../../../graphql/type/ReporterType";
import i18n from "i18next";
import {getQueryParam} from "../../../util/queryparam";

interface EditIncidentPageProps {
    id: string
}

const GQ = gql`query editIncidentPage($id: ID!, $language: String!) {
    getIncident(id: $id) {
        id
        titleGerman
        titleEnglish
        titleFrench
        
        tagsGerman
        tagsEnglish
        tagsFrench
        
        negativeExperienceGerman
        negativeExperienceEnglish
        negativeExperienceFrench
        
        backgroundInformationGerman
        backgroundInformationEnglish
        backgroundInformationFrench
        
        reasonsGerman
        reasonsEnglish
        reasonsFrench
        
        animalAgeGroup {
            id
            caption(language: $language)
        }
        animalGender {
            id
            caption(language: $language)
        }
        animalHygieneStatus {
            id
            caption(language: $language)
        }
        animalPregnantStatus {
            id
            caption(language: $language)
        }
        animalSpecies {
            id
            caption(language: $language)
        }
        
        incidentContext {
            id
            caption(language: $language)
        }
        incidentContributionFactors {
            id
            caption(language: $language)
        }
        incidentDegreeOfDistress {
            id
            caption(language: $language)
        }
        incidentDisciplines {
            id
            caption(language: $language)
        }
        incidentExperimentResultUsability {
            id
            caption(language: $language)
        }
        incidentFrequency {
            id
            caption(language: $language)
        }
        reporterType  {
            id
            caption(language: $language)
        }
        
        periodFrom
        periodTo
        
        unlocked
    }
    allAnimalAgeGroups {
        id
        caption(language: $language)
    }
    allAnimalGenders {
        id
        caption(language: $language)
    }
    allAnimalHygieneStatus {
        id
        caption(language: $language)
    }
    allAnimalPregnantStatus {
        id
        caption(language: $language)
    }
    allAnimalSpecies {
        id
        caption(language: $language)
    }
    allIncidentContexts {
        id
        caption(language: $language)
    }
    allIncidentContributionFactors(language: $language) {
        id
        caption(language: $language)
    }
    allIncidentDegreeOfDistress {
        id
        caption(language: $language)
    }
    allIncidentDisciplines(language: $language) {
        id
        caption(language: $language)
    }
    allIncidentExperimentResultUsabilities {
        id
        caption(language: $language)
    }
    allIncidentFrequencies {
        id
        caption(language: $language)
    }
    allReporterTypes {
        id
        caption(language: $language)
    }
}`;

interface EditIncidentPageRequest {
    id: string;
    language: string;
}

interface EditIncidentsPageResponse {
    getIncident: Incident
    allAnimalAgeGroups: AnimalAgeGroup[]
    allAnimalGenders: AnimalGender[]
    allAnimalHygieneStatus: AnimalHygieneStatus[]
    allAnimalPregnantStatus: AnimalPregnantStatus[]
    allAnimalSpecies: AnimalSpecies[]
    allIncidentContexts: IncidentContext[]
    allIncidentContributionFactors: IncidentContributionFactor[]
    allIncidentDegreeOfDistress: IncidentDegreeOfDistress[]
    allIncidentDisciplines: IncidentDiscipline[]
    allIncidentExperimentResultUsabilities: IncidentExperimentResultUsability[]
    allIncidentFrequencies: IncidentFrequency[]
    allReporterTypes: ReporterType[]
}

const GQ_UPDATE_INCIDENT = gql`mutation updateIncident(
    $id: ID!,
        
    $titleGerman: String,
    $titleEnglish: String,
    $titleFrench: String,
    
    $tagsGerman: String,
    $tagsEnglish: String,
    $tagsFrench: String,
    
    $negativeExperienceGerman: String,
    $negativeExperienceEnglish: String,
    $negativeExperienceFrench: String,
    
    $backgroundInformationGerman: String,
    $backgroundInformationEnglish: String,
    $backgroundInformationFrench: String,
    
    $reasonsGerman: String,
    $reasonsEnglish: String,
    $reasonsFrench: String,
    
    $animalAgeGroup: ID,
    $animalGender: ID,
    $animalHygieneStatus: ID,
    $animalPregnantStatus: ID,
    $animalSpecies: ID,
    
    $incidentContext: ID,
    $incidentContributionFactors: [ID!]!,
    $incidentDegreeOfDistress: ID,
    $incidentDisciplines: [ID!]!,
    $incidentExperimentResultUsability: ID,
    $incidentFrequency: ID,
    
    $reporterType: ID,
    
    $periodFrom: Int,
    $periodTo: Int,
    
    $unlocked: Boolean!
) {
    updateIncident(
        id: $id,
        
        titleGerman: $titleGerman,
        titleEnglish: $titleEnglish,
        titleFrench: $titleFrench,
        
        tagsGerman: $tagsGerman,
        tagsEnglish: $tagsEnglish,
        tagsFrench: $tagsFrench,
        
        negativeExperienceGerman: $negativeExperienceGerman,
        negativeExperienceEnglish: $negativeExperienceEnglish,
        negativeExperienceFrench: $negativeExperienceFrench,
        
        backgroundInformationGerman: $backgroundInformationGerman,
        backgroundInformationEnglish: $backgroundInformationEnglish,
        backgroundInformationFrench: $backgroundInformationFrench,
        
        reasonsGerman: $reasonsGerman,
        reasonsEnglish: $reasonsEnglish,
        reasonsFrench: $reasonsFrench,
        
        animalAgeGroup: $animalAgeGroup
        animalGender: $animalGender
        animalHygieneStatus: $animalHygieneStatus
        animalPregnantStatus: $animalPregnantStatus
        animalSpecies: $animalSpecies
        
        incidentContext: $incidentContext
        incidentContributionFactors: $incidentContributionFactors
        incidentDegreeOfDistress: $incidentDegreeOfDistress
        incidentDisciplines: $incidentDisciplines
        incidentExperimentResultUsability: $incidentExperimentResultUsability
        incidentFrequency: $incidentFrequency
        
        reporterType: $reporterType,
        
        periodFrom: $periodFrom,
        periodTo: $periodTo,
        
        unlocked: $unlocked
    ) {
        id
    }
}`;

interface UpdateIncidentRequest {
    id: string;
}

interface UpdateIncidentsResponse {
    id: string

    titleGerman: string
    titleEnglish: string
    titleFrench: string,

    tagsGerman: string
    tagsEnglish: string
    tagsFrench: string

    negativeExperienceGerman: string
    negativeExperienceEnglish: string
    negativeExperienceFrench: string

    backgroundInformationGerman: string
    backgroundInformationEnglish: string
    backgroundInformationFrench: string

    reasonsGerman: string
    reasonsEnglish: string
    reasonsFrench: string

    animalAgeGroup: string
    animalGender: string
    animalHygieneStatus: string
    animalPregnantStatus: string
    animalSpecies: string

    incidentContext: string
    incidentContributionFactors: string[]
    incidentDegreeOfDistress: string
    incidentDisciplines: string[]
    incidentExperimentResultUsability: string
    incidentFrequency: string

    reporterType: string

    periodFrom: string
    periodTo: string

    unlocked: boolean
}

const EditIncidentPage: React.FC<RouteComponentProps<{}, {}, EditIncidentPageProps>> = (props) => {
    const id = props.location.state?.id ? props.location.state?.id : getQueryParam("id")!!
    const {t} = useTranslation("admin/incident/EditIncidentPage");

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<EditIncidentsPageResponse, EditIncidentPageRequest>(GQ, {
        variables: {
            id: id,
            language: i18n.language
        },
        skip: id == null
    });

    const [gqUpdateIncident] = useMutation<UpdateIncidentsResponse, UpdateIncidentRequest>(GQ_UPDATE_INCIDENT, {
        onCompleted: () => {
            history.push(INCIDENTS_PAGE_URL)
        },
        refetchQueries: [
            {
                query: gql`query allIncidents {
                    allIncidents(language: "de")  {
                        id
                        title(language: "de")
                        titleGerman
                        titleEnglish
                        titleFrench
                        tags(language: "de")
                        tagsGerman
                        tagsEnglish
                        tagsFrench
                        negativeExperience(language: "de")
                        negativeExperienceGerman
                        negativeExperienceEnglish
                        negativeExperienceFrench
                        backgroundInformation(language: "de")
                        backgroundInformationGerman
                        backgroundInformationEnglish
                        backgroundInformationFrench
                        reasons(language: "de")
                        reasonsGerman
                        reasonsEnglish
                        reasonsFrench
                        animalAgeGroup {
                            id
                        }
                        animalGender {
                            id
                        }
                        animalHygieneStatus {
                            id
                        }
                        animalPregnantStatus {
                            id
                        }
                        animalSpecies {
                            id
                        }
                        incidentContext {
                            id
                        }
                        incidentContributionFactors {
                            id
                        }
                        incidentDegreeOfDistress {
                            id
                        }
                        incidentDisciplines {
                            id
                        }
                        incidentExperimentResultUsability {
                            id
                        }
                        incidentFrequency {
                            id
                        }
                        reporterType {
                            id
                        }
                        periodFrom
                        periodTo
                        unlocked
                    }
                }`
            },
            {
                query: gql`query allIncidents {
                    allIncidents(language: "en")  {
                        id
                        title(language: "en")
                        tags(language: "en")
                        negativeExperience(language: "en")
                        backgroundInformation(language: "en")
                        reasons(language: "en")
                    }
                }`
            },
            {
                query: gql`query allIncidents {
                    allIncidents(language: "fr") {
                        id
                        title(language: "fr")
                        tags(language: "fr")
                        negativeExperience(language: "fr")
                        backgroundInformation(language: "fr")
                        reasons(language: "fr")
                    }
                }`
            }
        ]
    });

    const history = useHistory()

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={INCIDENTS_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Fall")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        titleGerman: gq.data?.getIncident?.titleGerman,
                        titleEnglish: gq.data?.getIncident?.titleEnglish,
                        titleFrench: gq.data?.getIncident?.titleFrench,

                        tagsGerman: gq.data?.getIncident?.tagsGerman,
                        tagsEnglish: gq.data?.getIncident?.tagsEnglish,
                        tagsFrench: gq.data?.getIncident?.tagsFrench,

                        negativeExperienceGerman: gq.data?.getIncident?.negativeExperienceGerman,
                        negativeExperienceEnglish: gq.data?.getIncident?.negativeExperienceEnglish,
                        negativeExperienceFrench: gq.data?.getIncident?.negativeExperienceFrench,

                        backgroundInformationGerman: gq.data?.getIncident?.backgroundInformationGerman,
                        backgroundInformationEnglish: gq.data?.getIncident?.backgroundInformationEnglish,
                        backgroundInformationFrench: gq.data?.getIncident?.backgroundInformationFrench,

                        reasonsGerman: gq.data?.getIncident?.reasonsGerman,
                        reasonsEnglish: gq.data?.getIncident?.reasonsEnglish,
                        reasonsFrench: gq.data?.getIncident?.reasonsFrench,

                        animalAgeGroup: gq.data?.getIncident?.animalAgeGroup?.id,
                        animalGender: gq.data?.getIncident?.animalGender?.id,
                        animalHygieneStatus: gq.data?.getIncident?.animalHygieneStatus?.id,
                        animalPregnantStatus: gq.data?.getIncident?.animalPregnantStatus?.id,
                        animalSpecies: gq.data?.getIncident?.animalSpecies?.id,

                        incidentContext: gq.data?.getIncident?.incidentContext?.id,
                        incidentContributionFactors: gq.data?.getIncident?.incidentContributionFactors?.map((it) => it.id),
                        incidentDegreeOfDistress: gq.data?.getIncident?.incidentDegreeOfDistress?.id,
                        incidentDisciplines: gq.data?.getIncident?.incidentDisciplines?.map((it) => it.id),
                        incidentExperimentResultUsability: gq.data?.getIncident?.incidentExperimentResultUsability?.id,
                        incidentFrequency: gq.data?.getIncident?.incidentFrequency?.id,

                        reporterType: gq.data?.getIncident?.reporterType?.id,

                        periodFrom: gq.data?.getIncident?.periodFrom,
                        periodTo: gq.data?.getIncident?.periodTo,

                        unlocked: !!gq.data?.getIncident?.unlocked
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqUpdateIncident({
                            variables: {
                                id: id,
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Titel")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="titleGerman">{t("Deutsch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.titleGerman ? " is-invalid" : "")} id="titleGerman" name="titleGerman"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.titleGerman ? <div className="invalid-feedback">{t(status.titleGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="titleEnglish">{t("Englisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.titleEnglish ? " is-invalid" : "")} id="titleEnglish" name="titleEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.titleEnglish ? <div className="invalid-feedback">{t(status.titleEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="titleFrench">{t("Französisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.titleFrench ? " is-invalid" : "")} id="titleFrench" name="titleFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.titleFrench ? <div className="invalid-feedback">{t(status.titleFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Stichwörter")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="tagsGerman">{t("Deutsch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.tagsGerman ? " is-invalid" : "")} id="tagsGerman" name="tagsGerman"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.tagsGerman ? <div className="invalid-feedback">{t(status.tagsGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="tagsEnglish">{t("Englisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.tagsEnglish ? " is-invalid" : "")} id="tagsEnglish" name="tagsEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.tagsEnglish ? <div className="invalid-feedback">{t(status.tagsEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="tagsFrench">{t("Französisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.tagsFrench ? " is-invalid" : "")} id="tagsFrench" name="tagsFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.tagsFrench ? <div className="invalid-feedback">{t(status.tagsFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Negativerfahrung")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="negativeExperienceGerman">{t("Deutsch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.negativeExperienceGerman ? " is-invalid" : "")}
                                                       id="negativeExperienceGerman" name="negativeExperienceGerman"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.negativeExperienceGerman ? <div className="invalid-feedback">{t(status.negativeExperienceGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="negativeExperienceEnglish">{t("Englisch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.negativeExperienceEnglish ? " is-invalid" : "")}
                                                       id="negativeExperienceEnglish" name="negativeExperienceEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.negativeExperienceEnglish ? <div className="invalid-feedback">{t(status.negativeExperienceEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="negativeExperienceFrench">{t("Französisch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.negativeExperienceFrench ? " is-invalid" : "")}
                                                       id="negativeExperienceFrench" name="negativeExperienceFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.negativeExperienceFrench ? <div className="invalid-feedback">{t(status.negativeExperienceFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Hintergrundinformation")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="backgroundInformationGerman">{t("Deutsch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.backgroundInformationGerman ? " is-invalid" : "")}
                                                       id="backgroundInformationGerman" name="backgroundInformationGerman"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.backgroundInformationGerman ? <div className="invalid-feedback">{t(status.backgroundInformationGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="backgroundInformationEnglish">{t("Englisch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.backgroundInformationEnglish ? " is-invalid" : "")}
                                                       id="backgroundInformationEnglish" name="backgroundInformationEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.backgroundInformationEnglish ? <div className="invalid-feedback">{t(status.backgroundInformationEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="backgroundInformationFrench">{t("Französisch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.backgroundInformationFrench ? " is-invalid" : "")}
                                                       id="backgroundInformationFrench" name="backgroundInformationFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.backgroundInformationFrench ? <div className="invalid-feedback">{t(status.backgroundInformationFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Gründe")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="reasonsGerman">{t("Deutsch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.reasonsGerman ? " is-invalid" : "")} id="reasonsGerman"
                                                       name="reasonsGerman"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.reasonsGerman ? <div className="invalid-feedback">{t(status.reasonsGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="reasonsEnglish">{t("Englisch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.reasonsEnglish ? " is-invalid" : "")} id="reasonsEnglish"
                                                       name="reasonsEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.reasonsEnglish ? <div className="invalid-feedback">{t(status.reasonsEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="reasonsFrench">{t("Französisch")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.reasonsFrench ? " is-invalid" : "")} id="reasonsFrench"
                                                       name="reasonsFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.reasonsFrench ? <div className="invalid-feedback">{t(status.reasonsFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Tier")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="animalSpecies">{t("Tierart")}</label>
                                                <Field as="select" className={"form-select" + (status && status.animalSpecies ? " is-invalid" : "")} id="animalSpecies" name="animalSpecies">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allAnimalSpecies?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.animalSpecies ? <div className="invalid-feedback">{t(status.animalSpecies)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="animalAgeGroup">{t("Altersgruppe")}</label>
                                                <Field as="select" className={"form-select" + (status && status.animalAgeGroup ? " is-invalid" : "")} id="animalAgeGroup"
                                                       name="animalAgeGroup">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allAnimalAgeGroups?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.animalAgeGroup ? <div className="invalid-feedback">{t(status.animalAgeGroup)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="animalGender">{t("Geschlecht")}</label>
                                                <Field as="select" className={"form-select" + (status && status.animalGender ? " is-invalid" : "")} id="animalGender" name="animalGender">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allAnimalGenders?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.animalGender ? <div className="invalid-feedback">{t(status.animalGender)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="animalPregnantStatus">{t("Tragend")}</label>
                                                <Field as="select" className={"form-select" + (status && status.animalPregnantStatus ? " is-invalid" : "")} id="animalPregnantStatus"
                                                       name="animalPregnantStatus">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allAnimalPregnantStatus?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.animalPregnantStatus ? <div className="invalid-feedback">{t(status.animalPregnantStatus)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="animalHygieneStatus">{t("Hygienestatus")}</label>
                                                <Field as="select" className={"form-select" + (status && status.animalHygieneStatus ? " is-invalid" : "")} id="animalHygieneStatus"
                                                       name="animalHygieneStatus">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allAnimalHygieneStatus?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.animalHygieneStatus ? <div className="invalid-feedback">{t(status.animalHygieneStatus)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Fall")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="incidentContext">{t("Kontext")}</label>
                                                <Field as="select" className={"form-select" + (status && status.incidentContext ? " is-invalid" : "")} id="incidentContext" name="incidentContext">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allIncidentContexts?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.incidentContext ? <div className="invalid-feedback">{t(status.incidentContext)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="incidentContributionFactors">{t("Faktoren")}</label>
                                                <Field as="select" className={"form-select" + (status && status.incidentContributionFactors ? " is-invalid" : "")} id="incidentContributionFactors"
                                                       name="incidentContributionFactors" multiple={true}>
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allIncidentContributionFactors?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.incidentContributionFactors ? <div className="invalid-feedback">{t(status.incidentContributionFactors)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="allIncidentDegreeOfDistress">{t("Stresslevel")}</label>
                                                <Field as="select" className={"form-select" + (status && status.incidentDegreeOfDistress ? " is-invalid" : "")} id="incidentDegreeOfDistress"
                                                       name="incidentDegreeOfDistress">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allIncidentDegreeOfDistress?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.incidentDegreeOfDistress ? <div className="invalid-feedback">{t(status.incidentDegreeOfDistress)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="incidentDisciplines">{t("Themengebiet")}</label>
                                                <Field as="select" className={"form-select" + (status && status.incidentDisciplines ? " is-invalid" : "")} id="incidentDisciplines"
                                                       name="incidentDisciplines" multiple={true}>
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allIncidentDisciplines?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.incidentDisciplines ? <div className="invalid-feedback">{t(status.incidentDisciplines)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="incidentExperimentResultUsability">{t("Verwendbar")}</label>
                                                <Field as="select" className={"form-select" + (status && status.incidentExperimentResultUsability ? " is-invalid" : "")}
                                                       id="incidentExperimentResultUsability" name="incidentExperimentResultUsability">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allIncidentExperimentResultUsabilities?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.incidentExperimentResultUsability ? <div className="invalid-feedback">{t(status.incidentExperimentResultUsability)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="incidentFrequency">{t("Häufigkeit")}</label>
                                                <Field as="select" className={"form-select" + (status && status.incidentFrequency ? " is-invalid" : "")} id="incidentFrequency"
                                                       name="incidentFrequency">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allIncidentFrequencies?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.incidentFrequency ? <div className="invalid-feedback">{t(status.incidentFrequency)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row gx-6">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="periodFrom">{t("In welchem Zeitraum haben die Ereignisse stattgefunden?")}</label>
                                                <div className="row g-4">
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <div className="input-group-text">{t("von")}</div>
                                                            <Field type="text" className="form-control ps-2" id="periodFrom" name="periodFrom" placeholder="[Jahr]"/>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <div className="input-group-text">{t("bis")}</div>
                                                            <input type="text" className="form-control ps-2" id="periodTO" name="periodTo" placeholder="[Jahr]"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Melder")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="reporterType">{t("Berufsgruppe")}</label>
                                                <Field as="select" className={"form-select" + (status && status.reporterType ? " is-invalid" : "")} id="reporterType"
                                                       name="reporterType">
                                                    <option value="-1">{t("Bitte wählen")}</option>
                                                    {gq.data?.allReporterTypes?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                    {status && status.reporterType ? <div className="invalid-feedback">{t(status.reporterType)}</div> : <></>}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Aktiviert")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <Field type="checkbox" className={"" + (status && status.unlocked ? " is-invalid" : "")} id="unlocked" name="unlocked"/>
                                                <label htmlFor="enabled">Freigeschaltet</label>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.unlocked ? <div className="invalid-feedback">{t(status.unlocked)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>Speichern</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>

            <Footer/>
        </>
    );
}

export const EDIT_INCIDENT_PAGE_URL = "/admin/incidents/incident/edit"

export default withRouter(EditIncidentPage);
