import React from "react";
import Header from "./../Header"
import Footer from "./../Footer"
import Nav from "./Nav";
import {useTranslation} from "react-i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";

function TeamPage() {

    const {t} = useTranslation("project/TeamPage");

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "TeamPage"})
    })

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="mt-5 mb-4">
                    <h1>{t("Das Team")}</h1>
                </div>

                <p>{t("Sabine Bischoff")}</p>

                <p>{t("Astrid Enkelmann")}</p>

                <p>{t("Christoph Henniger")}</p>

            </div>

            <Footer/>
        </>
    );
}

export const TEAM_PAGE_URL="/project/team"

export default TeamPage;
