import {useQuery} from "@apollo/client";
import {GQ_LOGGED_IN, LoggedInResponse, logout} from "../util/auth/auth";
import React from "react";
import {useTranslation} from "react-i18next";

export const Logout = () => {

    const {t} = useTranslation("Logout");

    const {data} = useQuery<LoggedInResponse>(GQ_LOGGED_IN);

    return (data?.isLoggedIn ? <li className="nav-item">
        <button className="btn link nav-link" onClick={(e: React.MouseEvent) => {
            logout()
        }}>{t("Abmelden")}
        </button>
    </li> : <></>)
}