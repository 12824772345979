import React from "react";
import {Link} from "react-router-dom";
import {INCIDENT_PAGE_URL} from "./database/IncidentPage";
import {Incident} from "../graphql/type/Incident";
import {News} from "../graphql/type/News";
import {gql, useQuery} from "@apollo/client";
import {NEWS_PAGE_URL} from "./project/news/NewsPage";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const GQ = gql`query news($language: String!) {
    getNewestIncident {
        id
        title(language: $language)
    }
    getNewsList(limit: 3) {
        id
        title(language: $language)
        subtitle(language: $language)
        from
        to
    }
}`;

interface NewsComponentRequest {
    language: string
}

interface NewsComponentResponse {
    getNewestIncident: Incident
    getNewsList: News[];
}

function NewsComponent() {

    const {t} = useTranslation("NewsComponent");

    const {loading, error, data} = useQuery<NewsComponentResponse, NewsComponentRequest>(GQ, {
        variables: {
            language: i18n.language
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (<>
        <div className="bg-primary px-4 py-3 mb-3">
            <h2 className="mb-0 text-uppercase text-white">news</h2>
        </div>

        {data?.getNewestIncident && <div className="teaser mb-3">
            <div className="teaser-content p-4">
                <p>{t("Neuer Falleintrag")}: {data?.getNewestIncident?.title}</p>
            </div>
            <div className="teaser-more">
                <Link to={{pathname: INCIDENT_PAGE_URL, state: {id: data?.getNewestIncident.id}}}><i className="bi bi-chevron-right"/></Link>
            </div>
        </div>}

        {data?.getNewsList?.map((it) => (<div className="teaser mb-3" key={it.id}>
            <div className="teaser-content px-4 py-3">
                <div className="date text-primary">
                    <i className="bi bi-calendar-week"/>
                    <span>{it.from}</span>
                </div>

                <p>{it.title}</p>
                {it.subtitle && <p className="location">{it.subtitle}</p>}
            </div>
            <div className="teaser-more">
                <Link to={{pathname: NEWS_PAGE_URL, state: {id: it.id}}}><i className="bi bi-chevron-right"/></Link>
            </div>
        </div>))}

        {/*<div className="teaser mb-3">*/}
        {/*    <div className="teaser-content px-4 py-3">*/}
        {/*        <div className="date text-primary">*/}
        {/*            <i className="bi bi-calendar-week"/>*/}
        {/*            <span>15. März 2021</span>*/}
        {/*        </div>*/}

        {/*        <p>Lorem ipsum dolor sit amet, consectetuer</p>*/}
        {/*        <p className="location">Schwerin | statdhalle</p>*/}
        {/*    </div>*/}
        {/*    <div className="teaser-more">*/}
        {/*        <i className="bi bi-chevron-right"/>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="teaser mb-3">*/}
        {/*    <div className="teaser-content px-4 py-3">*/}
        {/*        <div className="date text-green">*/}
        {/*            <i className="bi bi-calendar-week"/>*/}
        {/*            <span>05. Mai 2021</span>*/}
        {/*        </div>*/}

        {/*        <p>Lorem ipsum dolor sit amet, consectetuer</p>*/}
        {/*        <p className="location">Jena | Universitätsklinikum</p>*/}
        {/*    </div>*/}
        {/*    <div className="teaser-more">*/}
        {/*        <i className="bi bi-chevron-right"/>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </>);
}

export default NewsComponent;