import React from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./Nav";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {SET_PASSWORD_PAGE} from "./SetPasswordPage";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const SetPasswordConfirmationPage: React.FC<RouteComponentProps<{}, {}, {}>> = (props) => {

    const {t} = useTranslation("SetPasswordConfirmationPage")

    const history = useHistory()

    if (!props.location.state) {
        history.push(SET_PASSWORD_PAGE)
    }

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "SetPasswordConfirmationPage"})
    })

    return (<>
        <Header/>

        <Nav/>

        <div className="container mt-5 mb-4">
            <h1>{t("Passwort erfolgreich geändert")}</h1>
        </div>

        <p>{t("Vielen Dank, dass sie Ihr Passwort gesetzt haben.")}</p>

        <Footer/>
    </>);
}

export const SET_PASSWORD_CONFIRMATION_PAGE = "/set_password_confirmation"

export default withRouter(SetPasswordConfirmationPage)
