import {gql, makeVar} from "@apollo/client";
import client from "../../graphql/ApolloClient";


export interface LoggedInResponse {
    isLoggedIn: boolean
}

export const GQ_LOGGED_IN = gql`
    query isLoggedIn {
        isLoggedIn
    }
`;

export const login = (token: string) => {
    if (token == null) {
        return
    }

    isLoggedIn(true)
    localStorage.setItem("AUTH_TOKEN", token)
}

export const logout = () => {
    console.log("logout")
    // TODO: client.cache.evict()
    client.cache.reset()
    localStorage.clear()
    isLoggedIn(false)
}

export const isLoggedIn = makeVar<Boolean>(!!localStorage.getItem("AUTH_TOKEN"))