import React from "react";
import Header from "./../Header"
import Footer from "./../Footer"
import Nav from "./Nav";
import {useTranslation, Trans} from "react-i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {gql, useQuery} from "@apollo/client";

const GQ = gql`query ProjectPage {
    getConfig {
        id
        baseUrl
    }
}`;

interface ProjectPageResponse {
    getConfig: {
        baseUrl: String
    }
}

function ProjectPage() {

    const {loading, error, data} = useQuery<ProjectPageResponse>(GQ);

    const {t} = useTranslation("project/ProjectPage");

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "ProjectPage"})
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (<>
        <Header/>

        <Nav/>

        <div className="container">

            <div className="mt-5 mb-4">
                <h1>{t("Das Projekt")}</h1>
            </div>

            <p>{t("absatz1")}</p>

            <p>{t("absatz2")}:</p>

            <ul>
                <li><Trans t={t} i18nKey="absatz2_1" tOptions={
                    {url: data?.getConfig.baseUrl}
                }><a href={"https://" + data?.getConfig.baseUrl}>XXX</a></Trans></li>
                <li><Trans t={t}>absatz2_2</Trans></li>
                <li>{t("absatz2_3")}</li>
                <li>{t("absatz2_4")}</li>
                <li>{t("absatz2_5")}</li>
            </ul>

        </div>

        <Footer/>
    </>);
}

export const PROJECT_PAGE_URL = "/project/overview"

export default ProjectPage;
