import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AdminHomePage, {ADMIN_HOME_PAGE_URL} from "./AdminHomePage";
import NewsListPage, {NEWS_LIST_PAGE_URL} from "./news/NewsListPage";
import EditNewsPage, {EDIT_NEWS_PAGE_URL} from "./news/EditNewsPage";
import DeleteNewsPage, {DELETE_NEWS_PAGE_URL} from "./news/DeleteNewsPage";
import AnimalAgeGroupsPage, {ANIMAL_AGE_GROUPS_PAGE_URL} from "./animalagegroup/AnimalAgeGroupsPage";
import EditAnimalAgeGroupPage, {EDIT_ANIMAL_AGE_GROUP_PAGE_URL} from "./animalagegroup/EditAnimalAgeGroupPage";
import DeleteAnimalAgeGroupPage, {DELETE_ANIMAL_AGE_GROUP_PAGE_URL} from "./animalagegroup/DeleteAnimalAgeGroupPage";
import AnimalGendersPage, {ANIMAL_GENDERS_PAGE_URL} from "./animalgender/AnimalGendersPage";
import EditAnimalGenderPage, {EDIT_ANIMAL_GENDER_PAGE_URL} from "./animalgender/EditAnimalGenderPage";
import DeleteAnimalGenderPage, {DELETE_ANIMAL_GENDER_PAGE_URL} from "./animalgender/DeleteAnimalGenderPage";
import AnimalHygieneStatusPage, {ANIMAL_HYGIENE_STATUS_PAGE_URL} from "./animalhygienestatus/AnimalHygieneStatusPage";
import EditAnimalHygieneStatusPage, {EDIT_ANIMAL_HYGIENE_STATUS_PAGE_URL} from "./animalhygienestatus/EditAnimalHygieneStatusPage";
import DeleteAnimalHygieneStatusPage, {DELETE_ANIMAL_HYGIENE_STATUS_PAGE_URL} from "./animalhygienestatus/DeleteAnimalHygieneStatusPage";
import AnimalSpeciesPage, {ANIMAL_SPECIES_PAGE_URL} from "./animalspecies/AnimalSpeciesPage";
import EditAnimalSpeciesPage, {EDIT_ANIMAL_SPECIES_PAGE_URL} from "./animalspecies/EditAnimalSpeciesPage";
import DeleteAnimalSpeciesPage, {DELETE_ANIMAL_SPECIES_PAGE_URL} from "./animalspecies/DeleteAnimalSpeciesPage";
import AnimalPregnantStatusPage, {ANIMAL_PREGNANT_STATUS_PAGE_URL} from "./animalpregnantstatus/AnimalPregnantStatusPage";
import EditAnimalPregnantStatusPage, {EDIT_ANIMAL_PREGNANT_STATUS_PAGE_URL} from "./animalpregnantstatus/EditAnimalPregnantStatusPage";
import DeleteAnimalPregnantStatusPage, {DELETE_ANIMAL_PREGNANT_STATUS_PAGE_URL} from "./animalpregnantstatus/DeleteAnimalPregnantStatusPage";
import ReporterTypesPage, {REPORTER_TYPES_PAGE_URL} from "./reportertype/ReporterTypesPage";
import DeleteReporterTypePage, {DELETE_REPORTER_TYPE_PAGE_URL} from "./reportertype/DeleteReporterTypePage";
import EditReporterTypePage, {EDIT_REPORTER_TYPE_PAGE_URL} from "./reportertype/EditReporterTypePage";
import IncidentContextsPage, {INCIDENT_CONTEXTS_PAGE_URL} from "./incidentcontext/IncidentContextsPage";
import EditIncidentContextPage, {EDIT_INCIDENT_CONTEXT_PAGE_URL} from "./incidentcontext/EditIncidentContextPage";
import DeleteIncidentContextPage, {DELETE_INCIDENT_CONTEXT_PAGE_URL} from "./incidentcontext/DeleteIncidentContextPage";
import IncidentContributionFactorsPage, {INCIDENT_CONTRIBUTION_FACTORS_PAGE_URL} from "./incidentcontributionfactor/IncidentContributionFactorsPage";
import EditIncidentContributionFactorPage, {EDIT_INCIDENT_CONTRIBUTION_FACTOR_PAGE_URL} from "./incidentcontributionfactor/EditIncidentContributionFactorPage";
import DeleteIncidentContributionFactorPage, {DELETE_INCIDENT_CONTRIBUTION_FACTOR_PAGE_URL} from "./incidentcontributionfactor/DeleteIncidentContributionFactorPage";
import IncidentDegreeOfDistressPage, {INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL} from "./incidentdegreeofdistress/IncidentDegreeOfDistressPage";
import EditIncidentDegreeOfDistressPage, {EDIT_INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL} from "./incidentdegreeofdistress/EditIncidentDegreeOfDistressPage";
import DeleteIncidentDegreeOfDistressPage, {DELETE_INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL} from "./incidentdegreeofdistress/DeleteIncidentDegreeOfDistressPage";
import IncidentExperimentResultUsabilitiesPage, {INCIDENT_EXPERIMENT_RESULT_USABILITIES_PAGE_URL} from "./incidentexperimentusability/IncidentExperimentResultUsabilitiesPage";
import EditIncidentExperimentResultUsabilityPage, {EDIT_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL} from "./incidentexperimentusability/EditIncidentExperimentResultUsabilityPage";
import DeleteIncidentExperimentResultUsabilityPage, {DELETE_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL} from "./incidentexperimentusability/DeleteIncidentExperimentResultUsabilityPage";
import IncidentFrequenciesPage, {INCIDENT_FREQUENCIES_PAGE_URL} from "./incidentfrequency/IncidentFrequenciesPage";
import EditIncidentFrequencyPage, {EDIT_INCIDENT_FREQUENCY_PAGE_URL} from "./incidentfrequency/EditIncidentFrequencyPage";
import DeleteIncidentFrequencyPage, {DELETE_INCIDENT_FREQUENCY_PAGE_URL} from "./incidentfrequency/DeleteIncidentFrequencyPage";
import IncidentDisciplinesPage, {INCIDENT_DISCIPLINES_PAGE_URL} from "./incidentdiscipline/IncidentDisciplinesPage";
import EditIncidentDisciplinePage, {EDIT_INCIDENT_DISCIPLINE_PAGE_URL} from "./incidentdiscipline/EditIncidentDisciplinePage";
import DeleteIncidentDisciplinePage, {DELETE_INCIDENT_DISCIPLINE_PAGE_URL} from "./incidentdiscipline/DeleteIncidentDisciplinePage";
import IncidentsPage, {INCIDENTS_PAGE_URL} from "./incident/IncidentsPage";
import DeleteIncidentPage, {DELETE_INCIDENT_PAGE_URL} from "./incident/DeleteIncidentPage";
import PersonsPage, {PERSONS_PAGE_URL} from "./person/PersonsPage";
import DeletePersonPage, {DELETE_PERSON_PAGE_URL} from "./person/DeletePersonPage";
import EditPersonPage, {EDIT_PERSON_PAGE_URL} from "./person/EditPersonPage";
import EditIncidentPage, {EDIT_INCIDENT_PAGE_URL} from "./incident/EditIncidentPage";
import {gql, useQuery} from "@apollo/client";
import {User} from "../../graphql/type/User";
import CommentsPage, {COMMENTS_PAGE_URL} from "./incident/comment/CommentsPage";
import EditCommentPage, {EDIT_COMMENT_PAGE_URL} from "./incident/comment/EditCommentPage";
import DeleteCommentPage, {DELETE_COMMENT_PAGE_URL} from "./incident/comment/DeleteCommentPage";

const GQ = gql`query adminRouter {
    currentUser {
        person {
            admin
        }
    }
}`;

interface AdminRouterResponse {
    currentUser: User
}

function AdminRouter() {

    const {loading, error, data} = useQuery<AdminRouterResponse>(GQ);

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>
    if (data?.currentUser?.person?.admin !== true) return <p>no admin</p>

    return (

        <Switch>
            <Route exact path={ADMIN_HOME_PAGE_URL}><AdminHomePage/></Route>
            <Route exact path={ANIMAL_AGE_GROUPS_PAGE_URL}><AnimalAgeGroupsPage/></Route>
            <Route exact path={EDIT_ANIMAL_AGE_GROUP_PAGE_URL}><EditAnimalAgeGroupPage/></Route>
            <Route exact path={DELETE_ANIMAL_AGE_GROUP_PAGE_URL}><DeleteAnimalAgeGroupPage/></Route>

            <Route exact path={ANIMAL_GENDERS_PAGE_URL}><AnimalGendersPage/></Route>
            <Route exact path={EDIT_ANIMAL_GENDER_PAGE_URL}><EditAnimalGenderPage/></Route>
            <Route exact path={DELETE_ANIMAL_GENDER_PAGE_URL}><DeleteAnimalGenderPage/></Route>

            <Route exact path={ANIMAL_HYGIENE_STATUS_PAGE_URL}><AnimalHygieneStatusPage/></Route>
            <Route exact path={EDIT_ANIMAL_HYGIENE_STATUS_PAGE_URL}><EditAnimalHygieneStatusPage/></Route>
            <Route exact path={DELETE_ANIMAL_HYGIENE_STATUS_PAGE_URL}><DeleteAnimalHygieneStatusPage/></Route>

            <Route exact path={ANIMAL_PREGNANT_STATUS_PAGE_URL}><AnimalPregnantStatusPage/></Route>
            <Route exact path={EDIT_ANIMAL_PREGNANT_STATUS_PAGE_URL}><EditAnimalPregnantStatusPage/></Route>
            <Route exact path={DELETE_ANIMAL_PREGNANT_STATUS_PAGE_URL}><DeleteAnimalPregnantStatusPage/></Route>

            <Route exact path={ANIMAL_SPECIES_PAGE_URL}><AnimalSpeciesPage/></Route>
            <Route exact path={EDIT_ANIMAL_SPECIES_PAGE_URL}><EditAnimalSpeciesPage/></Route>
            <Route exact path={DELETE_ANIMAL_SPECIES_PAGE_URL}><DeleteAnimalSpeciesPage/></Route>

            <Route exact path={NEWS_LIST_PAGE_URL}><NewsListPage/></Route>
            <Route exact path={EDIT_NEWS_PAGE_URL}><EditNewsPage/></Route>
            <Route exact path={DELETE_NEWS_PAGE_URL}><DeleteNewsPage/></Route>

            <Route exact path={INCIDENTS_PAGE_URL}><IncidentsPage/></Route>
            <Route exact path={EDIT_INCIDENT_PAGE_URL}><EditIncidentPage/></Route>
            <Route exact path={DELETE_INCIDENT_PAGE_URL}><DeleteIncidentPage/></Route>

            <Route exact path={COMMENTS_PAGE_URL}><CommentsPage/></Route>
            <Route exact path={EDIT_COMMENT_PAGE_URL}><EditCommentPage/></Route>
            <Route exact path={DELETE_COMMENT_PAGE_URL}><DeleteCommentPage/></Route>

            <Route exact path={INCIDENT_CONTEXTS_PAGE_URL}><IncidentContextsPage/></Route>
            <Route exact path={EDIT_INCIDENT_CONTEXT_PAGE_URL}><EditIncidentContextPage/></Route>
            <Route exact path={DELETE_INCIDENT_CONTEXT_PAGE_URL}><DeleteIncidentContextPage/></Route>

            <Route exact path={INCIDENT_CONTRIBUTION_FACTORS_PAGE_URL}><IncidentContributionFactorsPage/></Route>
            <Route exact path={EDIT_INCIDENT_CONTRIBUTION_FACTOR_PAGE_URL}><EditIncidentContributionFactorPage/></Route>
            <Route exact path={DELETE_INCIDENT_CONTRIBUTION_FACTOR_PAGE_URL}><DeleteIncidentContributionFactorPage/></Route>

            <Route exact path={INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL}><IncidentDegreeOfDistressPage/></Route>
            <Route exact path={EDIT_INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL}><EditIncidentDegreeOfDistressPage/></Route>
            <Route exact path={DELETE_INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL}><DeleteIncidentDegreeOfDistressPage/></Route>

            <Route exact path={INCIDENT_DISCIPLINES_PAGE_URL}><IncidentDisciplinesPage/></Route>
            <Route exact path={EDIT_INCIDENT_DISCIPLINE_PAGE_URL}><EditIncidentDisciplinePage/></Route>
            <Route exact path={DELETE_INCIDENT_DISCIPLINE_PAGE_URL}><DeleteIncidentDisciplinePage/></Route>

            <Route exact path={INCIDENT_EXPERIMENT_RESULT_USABILITIES_PAGE_URL}><IncidentExperimentResultUsabilitiesPage/></Route>
            <Route exact path={EDIT_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL}><EditIncidentExperimentResultUsabilityPage/></Route>
            <Route exact path={DELETE_INCIDENT_EXPERIMENT_RESULT_USABILITY_PAGE_URL}><DeleteIncidentExperimentResultUsabilityPage/></Route>

            <Route exact path={INCIDENT_FREQUENCIES_PAGE_URL}><IncidentFrequenciesPage/></Route>
            <Route exact path={EDIT_INCIDENT_FREQUENCY_PAGE_URL}><EditIncidentFrequencyPage/></Route>
            <Route exact path={DELETE_INCIDENT_FREQUENCY_PAGE_URL}><DeleteIncidentFrequencyPage/></Route>

            <Route exact path={REPORTER_TYPES_PAGE_URL}><ReporterTypesPage/></Route>
            <Route exact path={EDIT_REPORTER_TYPE_PAGE_URL}><EditReporterTypePage/></Route>
            <Route exact path={DELETE_REPORTER_TYPE_PAGE_URL}><DeleteReporterTypePage/></Route>

            <Route exact path={PERSONS_PAGE_URL}><PersonsPage/></Route>
            <Route exact path={EDIT_PERSON_PAGE_URL}><EditPersonPage/></Route>
            <Route exact path={DELETE_PERSON_PAGE_URL}><DeletePersonPage/></Route>

            <Redirect to={ADMIN_HOME_PAGE_URL}/>
        </Switch>
    )
}

export const ADMIN_ROUTER_URL = "/admin"

export default AdminRouter;
