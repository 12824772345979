import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../../Header";
import Footer from "../../../Footer";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import Nav from "../../Nav";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {processFormResult} from "../../../../graphql/type/ErrorExtension";
import {Comment} from "../../../../graphql/type/Comment";
import {COMMENTS_PAGE_URL} from "./CommentsPage";

const GQ = gql`query deleteCommentPage($id: ID!) {
    getComment(id: $id) {
        id
        incident {
            id
        }
    }
}`;

interface DeleteCommentPageRequest {
    id: string
}

interface DeleteCommentPageResponse {
    getComment: Comment
}

const GQ_DELETE = gql`mutation deleteComment($id: ID!) {
    deleteComment(id: $id)
}`;

interface DeleteCommentRequest {
    id: string;
}

interface DeleteCommentResponse {
}

interface DeleteCommentPageProps {
    id: string
}

const DeleteCommentPage: React.FC<RouteComponentProps<{}, {}, DeleteCommentPageProps>> = (props) => {

    const {t} = useTranslation("admin/incident/DeleteCommentPage")
    const [incidentId, setIncidentId] = useState<String>()

    const history = useHistory();

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<DeleteCommentPageResponse, DeleteCommentPageRequest>(GQ, {
        variables: {
            id: props.location.state.id,
        },
        onCompleted: data => {
            setIncidentId(data.getComment.incident?.id)
        }
    })

    const [gqDelete] = useMutation<DeleteCommentResponse, DeleteCommentRequest>(GQ_DELETE, {
        onCompleted: () => {
            history.push({pathname: COMMENTS_PAGE_URL, state: {id: incidentId}});
        },
        refetchQueries: [
            {
                query: gql`query incidentsPage($id: ID!) {
                    getIncident(id: $id) {
                        id
                        allComments {
                            id
                        }
                        unlockedComments {
                            id
                        }
                    }
                }`,
                variables: {
                    id: incidentId
                }
            }
        ]
    });


    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={{pathname: COMMENTS_PAGE_URL, state: {id: incidentId}}}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Kommentar löschen")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        replaceWith: undefined
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqDelete({
                            variables: {
                                id: props.location.state.id
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)
                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <p>Soll der Kommentar wirklich gelöscht werden?</p>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-warning" disabled={isSubmitting}>Löschen</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>

            </div>

            <Footer/>
        </>
    );
}

export const DELETE_COMMENT_PAGE_URL = "/admin/incidents/incident/comments/comment/delete"

export default withRouter(DeleteCommentPage);
