import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../../Header";
import Footer from "../../../Footer";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Field, Form, Formik} from 'formik';
import Nav from "../../Nav";
import {processFormResult} from "../../../../graphql/type/ErrorExtension";
import {useTranslation} from "react-i18next";
import {getQueryParam} from "../../../../util/queryparam";
import {Comment} from "../../../../graphql/type/Comment";
import {COMMENTS_PAGE_URL} from "./CommentsPage";

interface EditIncidentPageProps {
    id: string
}

const GQ = gql`query editCommentPage($id: ID!) {
    getComment(id: $id) {
        id
        comment
        unlocked
        incident {
            id
        }
    }
}`;

interface EditCommentPageRequest {
    id: string;
}

interface EditCommentPageResponse {
    getComment: Comment
}

const GQ_UPDATE_COMMENT = gql`mutation updateComment(
    $id: ID!,
        
    $comment: String!,
    $unlocked: Boolean!
) {
    updateComment(
        id: $id,
        comment: $comment,
        unlocked: $unlocked
    ) {
        id
    }
}`;

interface UpdateCommentRequest {
    id: string;
}

interface UpdateCommentResponse {
    id: string
    comment: string
    unlocked: boolean
}

const EditCommentPage: React.FC<RouteComponentProps<{}, {}, EditIncidentPageProps>> = (props) => {
    const id = props.location.state?.id ? props.location.state?.id : getQueryParam("id")!!

    const {t} = useTranslation("admin/incident/comment/EditCommentPage");

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<EditCommentPageResponse, EditCommentPageRequest>(GQ, {
        variables: {
            id: id,
        }
    });

    const [gqUpdateIncident] = useMutation<UpdateCommentResponse, UpdateCommentRequest>(GQ_UPDATE_COMMENT, {
        onCompleted: () => {
            history.push({pathname: COMMENTS_PAGE_URL, state: {id: gq.data?.getComment?.incident?.id}})
        },
        refetchQueries: [
            {
                query: gql`query getComment($id: ID!) {
                    getComment(id: $id)  {
                        id
                        comment
                        unlocked
                    }
                }`,
                variables: {
                    id: id
                }
            }
        ]
    });

    const history = useHistory()

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={{pathname: COMMENTS_PAGE_URL, state: {id: gq.data?.getComment?.incident?.id}}}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Kommentar")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        comment: gq.data?.getComment?.comment,
                        unlocked: !!gq.data?.getComment?.unlocked
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqUpdateIncident({
                            variables: {
                                id: id,
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Kommentar")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="comment">{t("Kommentar")}</label>
                                                <Field type="text" className={"form-control" + (status && status.comment ? " is-invalid" : "")} id="comment" name="comment"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.comment ? <div className="invalid-feedback">{t(status.comment)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Aktiviert")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <Field type="checkbox" className={"" + (status && status.unlocked ? " is-invalid" : "")} id="unlocked" name="unlocked"/>
                                                <label htmlFor="enabled">Freigeschaltet</label>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.unlocked ? <div className="invalid-feedback">{t(status.unlocked)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>Speichern</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>

            <Footer/>
        </>
    );
}

export const EDIT_COMMENT_PAGE_URL = "/admin/incidents/incident/comments/comment/edit"

export default withRouter(EditCommentPage);
