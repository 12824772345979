import React from "react";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./database/Nav";
import {Trans, useTranslation} from "react-i18next";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {REPORT_INCIDENT_PAGE_URL} from "./ReportIncidentPage";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {HOME_PAGE_URL} from "./HomePage";
import {gql, useQuery} from "@apollo/client";

const ReportIncidentConfirmationPage: React.FC<RouteComponentProps<{}, {}, {}>> = (props) => {

    const GQ = gql`query ReportIncidentConfirmationPage {
        getConfig {
            emailPublic
        }
    }`;

    interface ReportIncidentConfirmationPageResponse {
        getConfig: {
            baseUrl: String
            emailPublic: String
        }
    }

    const {loading, error, data} = useQuery<ReportIncidentConfirmationPageResponse>(GQ);

    const {t} = useTranslation("ReportIncidentConfirmationPage");

    const history = useHistory()

    if (!props.location.state) {
        history.push(REPORT_INCIDENT_PAGE_URL)
    }

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "ReportIncidentConfirmationPage"})
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (<>
        <Header/>

        <Nav/>

        <div className="container">

            <div className="mt-5 mb-4">
                <h1>{t("Vielen Dank für Ihre Fallmeldung!")}</h1>
            </div>

            <p>{t("absatz1")}</p>

            <ol>
                <li>{t("Co-Autorenschaft")}</li>
                <li>{t("Fortbildung (1 Punkt)")}</li>
                <li>{t("Diskussion/Wissenschaftlicher Austausch")}</li>
            </ol>

            <p><Trans t={t} i18nKey="absatz2" tOptions={
                {email: data?.getConfig.emailPublic}
            }><a href={"mailto:" + data?.getConfig.emailPublic}>XXX</a></Trans></p>

            <p><Link to={HOME_PAGE_URL}>{t("Zurück zur Startseite")}</Link></p>

        </div>

        <Footer/>
    </>);
}

export const REPORT_INCIDENT_CONFIRMATION_PAGE_URL = "/report_incident_confirmation"

export default withRouter(ReportIncidentConfirmationPage);
