import React from "react";
import Header from "./../Header"
import Footer from "./../Footer"
import Nav from "./Nav";
import {useTranslation, Trans} from "react-i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {gql, useQuery} from "@apollo/client";

const GQ = gql`query FAQPage {
    getConfig {
        id
        baseUrl
        emailPublic
    }
}`;

interface FAQPageResponse {
    getConfig: {
        baseUrl: String
        emailPublic: String
    }
}

function FAQPage() {

    const {loading, error, data} = useQuery<FAQPageResponse>(GQ);

    const {t} = useTranslation("project/FAQPage");

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "FAQPage"})
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="mt-5 mb-4">
                    <h1>{t("FAQ")}</h1>
                </div>

                <h3>{t("faq1")}</h3>
                <p><Trans t={t} i18nKey="faq1_1" tOptions={
                    {email: data?.getConfig.emailPublic}
                }><a href={"mailto:" + data?.getConfig.emailPublic}>XXX</a></Trans></p>
                <h3>{t("faq2")}</h3>
                <p>{t("faq2_1")}</p>
                <h3>{t("faq3")}</h3>
                <p>{t("faq3_1")}</p>
                <p>{t("faq3_2")}</p>
                <h3>{t("faq4")}</h3>
                <p><Trans t={t} i18nKey="faq4_1" tOptions={
                    {url: data?.getConfig.baseUrl}
                }><a href={"https://" + data?.getConfig.baseUrl}>XXX</a></Trans></p>
                <p>{t("faq4_2")}</p>
                <h3>{t("faq5")}</h3>
                <p>{t("faq5_1")}</p>
                <p>{t("faq5_2")}</p>
                <h3>{t("faq6")}</h3>
                <p><Trans t={t} i18nKey="faq6_1" tOptions={
                    {url: "www.dsgvo-gesetz.de/"}
                }><a href={"https://www.dsgvo-gesetz.de/themen/auftragsverarbeitung"}>XXX</a></Trans></p>
                <p><Trans t={t} i18nKey="faq6_2" tOptions={
                    {url: "www.dsgvo-gesetz.de/themen/auftragsverarbeitung"}
                }><a href={"https://www.dsgvo-gesetz.de/themen/auftragsverarbeitung"}>XXX</a></Trans></p>
                <h3>{t("faq6-1")}</h3>
                <p>{t("faq6-1_1")}</p>
                <p>(A) - {t("faq6-1_2")}</p>
                <p>(B) - {t("faq6-1_3", {URL: data?.getConfig.baseUrl})}</p>
                <p>(C) - {t("faq6-1_4")}</p>
                <p>{t("faq6-1_5")}</p>
                <p><Trans t={t} i18nKey="faq6-1_6" tOptions={
                    {email: data?.getConfig.emailPublic}
                }><a href={"mailto:" + data?.getConfig.emailPublic}>XXX</a></Trans></p>
                <h3>{t("faq7")}</h3>
                <p>{t("faq7_1")}</p>
                <h3>{t("faq8")}</h3>
                <p>{t("faq8_1")}</p>
                <p>{t("faq8_2")}</p>

            </div>

            <Footer/>
        </>
    );
}

export const FAQ_PAGE_URL = "/project/faq"

export default FAQPage;
