import {gql, useLazyQuery, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Link} from "react-router-dom";
import {ADMIN_HOME_PAGE_URL} from "../AdminHomePage";
import React from "react";
import {useTranslation} from "react-i18next";
import Nav from "../Nav";
import {Incident} from "../../../graphql/type/Incident";
import {DELETE_INCIDENT_PAGE_URL} from "./DeleteIncidentPage";
import {EDIT_INCIDENT_PAGE_URL} from "./EditIncidentPage";
import i18n from "i18next";
import {COMMENTS_PAGE_URL} from "./comment/CommentsPage";

const GQ = gql`query incidentsPage($language: String!) {
    allIncidents(language: $language) {
        id
        title(language: $language)
        allComments {
            id
        }
    }
}`;

const GQ_INCIDENTS_AS_EXCEL = gql`query dowloadIncidentsAsExcel {
    downloadAllIncidentsAsExcel
}`;

interface IncidentsPageRequest {
    language: string
}

interface IncidentsPageResponse {
    allIncidents: Incident[]
}

interface IncidentsAsExcelResponse {
    downloadAllIncidentsAsExcel: string
}

const IncidentsPage = () => {

    const {t} = useTranslation("admin/incident/IncidentPage");

    const {loading, error, data} = useQuery<IncidentsPageResponse, IncidentsPageRequest>(GQ, {
        variables: {
            language: i18n.language
        }
    });

    const [downloadAsExcel ] = useLazyQuery<IncidentsAsExcelResponse>(GQ_INCIDENTS_AS_EXCEL, {
        fetchPolicy: "network-only",
        onCompleted: data => {
            const a = document.createElement('a');
            let now = new Date().toISOString()
            a.download = now + '_Faelle.xlsx';
            let mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            a.href = "data:" + mimeType + ";base64," + data.downloadAllIncidentsAsExcel
            a.click();
        }
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="container mb-5">
                    <Link to={ADMIN_HOME_PAGE_URL}>Zurück</Link>
                </div>

                <div className="container mb-3">
                    <h1>{t("Fälle")}</h1>
                    <a href="#" onClick={() => {
                        downloadAsExcel()
                    }}>Download as Excel</a>
                </div>

                <table className="table">

                    <thead>
                    <tr>
                        <th>Eintrag</th>
                        <th>Kommentare</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        // der slice() Aufruf wird benötigt, um das Array zu kopieren, da sonst sortierung nicht möglich ist
                        data?.allIncidents?.slice().sort((i1, i2) => i1.title!!.localeCompare(i2.title!!))?.map((it) => (<tr key={it.id}>
                            <td>{it.title}</td>
                            <td><Link to={{pathname: COMMENTS_PAGE_URL, state: {id: it.id}}}>{it.allComments?.length}</Link></td>
                            <td><Link to={{pathname: EDIT_INCIDENT_PAGE_URL, state: {id: it.id}}}>Bearbeiten</Link></td>
                            <td><Link to={{pathname: DELETE_INCIDENT_PAGE_URL, state: {id: it.id}}}>Löschen</Link></td>
                        </tr>))
                    }

                    </tbody>

                </table>

            </div>

            <Footer/>
        </>
    );
}
export const INCIDENTS_PAGE_URL = "/admin/incidents"

export default IncidentsPage;
