import {ApolloClient, ApolloLink, from, HttpLink, NormalizedCacheObject} from "@apollo/client";
import cache from "./Cache";

const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {}}) => ({
        headers: {
            ...headers,
            ...(localStorage.getItem("AUTH_TOKEN") ? {Authorization: localStorage.getItem("AUTH_TOKEN")} : {})
        }
    }));
    return forward(operation)
})

const httpLink = new HttpLink({uri: "/api",})

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: from([
        authLink,
        httpLink
    ]),
});

export default client;
