import {InMemoryCache} from "@apollo/client";
import {isLoggedIn} from "../util/auth/auth";

const cache:InMemoryCache = new InMemoryCache ({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn() {
                    return isLoggedIn()
                }
            }
        }
    }
});

export default cache;