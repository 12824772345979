import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {ReporterType} from "../../../graphql/type/ReporterType";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Form, Formik, Field} from 'formik';
import {REPORTER_TYPES_PAGE_URL} from "./ReporterTypesPage";
import Nav from "../Nav";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import {useTranslation} from "react-i18next";

interface EditReporterTypePageProps {
    id: string
}

const GQ = gql`query EditReporterTypePage($id: ID!) {
    getReporterType(id: $id) {
        id
        captionGerman
        captionEnglish
        captionFrench
        enabled
    }
}`;

interface EditReporterTypePageRequest {
    id: string;
}

interface EditReporterTypesPageResponse {
    getReporterType: ReporterType
}

const GQ_CREATE_OR_UPDATE_REPORTER_TYPE = gql`
  mutation createOrUpdateReporterType(
      $id: ID,
      $captionGerman: String,
      $captionEnglish: String,
      $captionFrench: String,
      $enabled: Boolean!
  ) {
    createOrUpdateReporterType(
      id: $id,
      captionGerman: $captionGerman,
      captionEnglish: $captionEnglish,
      captionFrench: $captionFrench,
      enabled: $enabled
    ) {
      id
    }
  }
`;

const EditReporterTypePage: React.FC<RouteComponentProps<{}, {}, EditReporterTypePageProps>> = (props) => {

    const {t} = useTranslation("admin/reportertype/EditReporterTypePage");

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<EditReporterTypesPageResponse, EditReporterTypePageRequest>(GQ, {
        variables: {
            id: props.location.state?.id
        },
        skip: props.location.state?.id == null
    });

    const [createOrEditReporterType] = useMutation(GQ_CREATE_OR_UPDATE_REPORTER_TYPE, {
        onCompleted: () => {
            history.push(REPORTER_TYPES_PAGE_URL)
        },
        refetchQueries: [
            {
                query: gql`query allReporterTypes {
                    allReporterTypes {
                        id
                        caption(language: "de")
                        captionGerman
                        captionEnglish
                        captionFrench
                        enabled
                    }
                }`
            },
            {
                query: gql`query allReporterTypes {
                    allReporterTypes {
                        id
                        caption(language: "en")
                        captionGerman
                        captionEnglish
                        captionFrench
                        enabled
                    }
                }`
            },
            {
                query: gql`query allReporterTypes {
                    allReporterTypes {
                        id
                        caption(language: "fr")
                        captionGerman
                        captionEnglish
                        captionFrench
                        enabled
                    }
                }`
            }
        ]
    });

    const history = useHistory()

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={REPORTER_TYPES_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Berufsgruppe")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        captionGerman: gq.data?.getReporterType?.captionGerman,
                        captionEnglish: gq.data?.getReporterType?.captionEnglish,
                        captionFrench: gq.data?.getReporterType?.captionFrench,
                        enabled: !!gq.data?.getReporterType?.enabled
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        createOrEditReporterType({
                            variables: {
                                id: props.location.state?.id,
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Bezeichnung")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="captionGerman">{t("Deutsch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.captionGerman ? " is-invalid" : "")} id="captionGerman" name="captionGerman" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.captionGerman ? <div className="invalid-feedback">{t(status.captionGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="captionEnglish">{t("Englisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.captionEnglish ? " is-invalid" : "")} id="captionEnglish" name="captionEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.captionEnglish ? <div className="invalid-feedback">{t(status.captionEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="captionFrench">{t("Französisch")}</label>
                                                <Field type="text" className={"form-control" + (status && status.captionFrench ? " is-invalid" : "")} id="captionFrench" name="captionFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.captionFrench ? <div className="invalid-feedback">{t(status.captionFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Aktiviert")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <Field type="checkbox" className={"" + (status && status.enabled ? " is-invalid" : "")} id="enabled" name="enabled"/>
                                                <label htmlFor="enabled">Aktiviert</label>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.enabled ? <div className="invalid-feedback">{t(status.enabled)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>Speichern</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>

            <Footer/>
        </>
    );
}

export const EDIT_REPORTER_TYPE_PAGE_URL = "/admin/reporter_types/reporter_type/edit"

export default withRouter(EditReporterTypePage);
