import {GraphQLError} from "graphql";

export interface ErrorExtension {
    errorKey: string
    locationKey?: string
}

export type FormResult = [string[] | undefined, any]

export const processFormResult = (graphQLErrors: readonly GraphQLError[]): FormResult => {

    const errors = graphQLErrors
        ?.flatMap((e: GraphQLError) => {
            return e.extensions?.errors ? e.extensions?.errors : []
        })
        ?.filter((e: ErrorExtension) => {
            return !e.locationKey
        })
        ?.map((e: ErrorExtension) => {
            return e.errorKey!
        })

    const fieldErrors = graphQLErrors
        ?.flatMap((e: GraphQLError) => {
            return e.extensions?.errors ? e.extensions?.errors : []
        })
        ?.filter((e: ErrorExtension) => {
            return !!e.locationKey
        })
        ?.map((e: ErrorExtension) => {
            return {
                [e.locationKey!]: e.errorKey
            }
        })
        ?.reduce((obj: any, e: any) => {
            return {
                ...obj,
                ...e
            }
        }, {})

    return [errors && errors.length > 0 ? errors : undefined, fieldErrors]
}