import React from "react";
import {Link} from "react-router-dom";
import Header from "./../../Header"
import Footer from "./../../Footer"
import {gql, useQuery} from "@apollo/client";
import {Person} from "../../../graphql/type/Person";
import {ADMIN_HOME_PAGE_URL} from "../AdminHomePage";
import Nav from "../Nav";
import {useTranslation} from "react-i18next";
import {DELETE_PERSON_PAGE_URL} from "./DeletePersonPage";
import {EDIT_PERSON_PAGE_URL} from "./EditPersonPage";

const GQ = gql`query personsPage {
    allPersons {
        id
        firstname
        lastname
        organisation
        user {
            username
        }
    }
}`;

interface PersonsPageResponse {
    allPersons: Person[];
}

function PersonsPage() {

    const gq = useQuery<PersonsPageResponse>(GQ);

    const {t} = useTranslation("admin/person/PersonsPage");

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="container mb-5">
                    <Link to={ADMIN_HOME_PAGE_URL}>Zurück</Link>
                </div>

                <div className="container mb-3">
                    <h1>{t("Benutzerverzeichnis")}</h1>
                </div>

                <table className="table">

                    <thead>
                    <tr>
                        <th>Nachname</th>
                        <th>Vorname</th>
                        <th>Benutzername</th>
                        <th>Einrichtung</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        // der slice() Aufruf wird benötigt, um das Array zu kopieren, da sonst sortierung nicht möglich ist
                        gq.data?.allPersons.slice().sort((i1, i2) => i1.lastname!.localeCompare(i2.lastname!)).map((it) => (<tr key={it.id}>
                            <td>{it.lastname}</td>
                            <td>{it.firstname}</td>
                            <td>{it.user?.username}</td>
                            <td>{it.organisation}</td>
                            <td><Link to={{pathname: EDIT_PERSON_PAGE_URL, state: {id: it.id}}}>Bearbeiten</Link></td>
                            <td><Link to={{pathname: DELETE_PERSON_PAGE_URL, state: {id: it.id}}}>Löschen</Link></td>
                        </tr>))
                    }

                    </tbody>

                </table>

            </div>

            <Footer/>
        </>
    );
}

export const PERSONS_PAGE_URL = "/admin/persons"

export default PersonsPage;
