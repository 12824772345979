import {gql, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {News} from "../../../graphql/type/News";
import {Link} from "react-router-dom";
import {EDIT_NEWS_PAGE_URL} from "./EditNewsPage";
import {DELETE_NEWS_PAGE_URL} from "./DeleteNewsPage";
import {ADMIN_HOME_PAGE_URL} from "../AdminHomePage";
import i18n from "i18next";
import React from "react";
import {useTranslation} from "react-i18next";
import Nav from "../Nav";

const GQ = gql`query newsListPage($language: String!) {
    getAllNews {
        id
        title(language: $language)
    }
}`;

interface NewsListPageRequest {
    language: string
}

interface NewsListPageResponse {
    getAllNews: News[]
}

function NewsListPage() {

    const {t} = useTranslation("admin/news/NewsListPage");

    const {loading, error, data} = useQuery<NewsListPageResponse, NewsListPageRequest>(GQ, {
        variables: {
            language: i18n.language
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="mb-5">
                    <Link to={ADMIN_HOME_PAGE_URL}>Zurück</Link>
                </div>

                <div className="mb-3">
                    <h1>{t("News")}</h1>
                </div>

                <div>
                    <Link to={EDIT_NEWS_PAGE_URL}>Neuer Newseintrag</Link>
                </div>

                <table className="table">

                    <thead>
                    <tr>
                        <th>Eintrag</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        // der slice() Aufruf wird benötigt, um das Array zu kopieren, da sonst sortierung nicht möglich ist
                        data?.getAllNews?.map((it) => (<tr key={it.id}>
                            <td>{it.title}</td>
                            <td><Link to={{pathname: EDIT_NEWS_PAGE_URL, state: {id: it.id}}}>Bearbeiten</Link></td>
                            <td><Link to={{pathname: DELETE_NEWS_PAGE_URL, state: {id: it.id}}}>Löschen</Link></td>
                        </tr>))}

                    </tbody>

                </table>

            </div>

            <Footer/>
        </>
    );
}

export const NEWS_LIST_PAGE_URL = "/admin/news"

export default NewsListPage;
