import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import Nav from "../Nav";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import {Person} from "../../../graphql/type/Person";
import {PERSONS_PAGE_URL} from "./PersonsPage";

const GQ = gql`query deletePersonPage($id: ID!) {
    getPerson(id: $id) {
        id
        user {
            username
        }
    }
}`;

interface DeletePersonPageRequest {
    id: string;
}

interface DeletePersonPageResponse {
    getPerson: Person
}

const GQ_DELETE = gql`mutation deletePerson($id: ID!) {
    deletePerson(id: $id)
}`;

interface DeletePersonRequest {
    id: string;
}

interface DeletePersonResponse {
}

interface DeletePersonPageProps {
    id: string
}

const DeletePersonPage: React.FC<RouteComponentProps<{}, {}, DeletePersonPageProps>> = (props) => {

    const {t} = useTranslation("admin/person/DeletePersonPage")

    const history = useHistory();

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<DeletePersonPageResponse, DeletePersonPageRequest>(GQ, {
        variables: {
            id: props.location.state.id
        }
    });

    const [gqDelete] = useMutation<DeletePersonResponse, DeletePersonRequest>(GQ_DELETE, {
        onCompleted: () => {
            history.push(PERSONS_PAGE_URL);
        },
        refetchQueries: [
            {
                query: gql`query personsPage {
                    allPersons {
                        id
                    }
                }`
            }
        ]
    });


    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={PERSONS_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Benutzer löschen")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        replaceWith: undefined
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqDelete({
                            variables: {
                                id: props.location.state.id
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)
                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <p>Soll der Benutzer <em>{gq.data?.getPerson?.user?.username}</em> wirklich gelöscht werden?</p>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-warning" disabled={isSubmitting}>Löschen</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>

            </div>

            <Footer/>
        </>
    );
}

export const DELETE_PERSON_PAGE_URL = "/admin/persons/person/delete"

export default withRouter(DeletePersonPage);
