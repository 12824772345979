import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import IncidentsPage, {INCIDENTS_PAGE_URL} from "./IncidentsPage";
import IncidentPage, {INCIDENT_PAGE_URL} from "./IncidentPage";

function DatabaseRouter() {

    return (
        <Switch>
            <Route exact path={INCIDENTS_PAGE_URL}><IncidentsPage/></Route>
            <Route exact path={INCIDENT_PAGE_URL}><IncidentPage/></Route>
            <Redirect to={INCIDENTS_PAGE_URL}/>
        </Switch>
    )
}

export const DATABASE_ROUTER_URL = "/database"

export default DatabaseRouter;
