import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Footer from "./Footer"
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import Nav from "./Nav";
import {REGISTER_PAGE_URL} from "./RegisterPage";
import {gql, useMutation} from "@apollo/client";
import {LoginResult} from "../graphql/type/LoginResult";
import {Form, Formik} from 'formik';
import {login as doLogin} from "../util/auth/auth";
import {processFormResult} from "../graphql/type/ErrorExtension";
import {HOME_PAGE_URL} from "./HomePage";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {REQUEST_NEW_PASSWORD_PAGE_URL} from "./RequestNewPasswordPage";
import cache from "../graphql/Cache";

const GQ_LOGIN = gql`mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      authToken
      user {
        id
      }
    }
}`;

const GQ_CURRENT_USER = gql`query currentUser {
    currentUser {
        person {
            admin
        }
    }
}`;

interface LoginInput {
    username: String
    password: String
}

interface LoginPageProps {
    fromLocation?: string
}

const LoginPage = (props: RouteComponentProps<{}, {}, LoginPageProps>) => {

    const [login] = useMutation<{ login: LoginResult }, LoginInput>(GQ_LOGIN, {
        onCompleted: (data) => {
            doLogin(data.login.authToken!)

            cache.writeQuery({
                query: GQ_CURRENT_USER,
                data: {currentUser: data.login.user}
            })

            history.push(props.location.state?.fromLocation ? props.location.state?.fromLocation : HOME_PAGE_URL)
        }
    })

    const {t} = useTranslation("LoginPage");

    const history = useHistory()

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const {trackPageView} = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "LoginPage"})
    })

    return (<>
        <Header/>

        <Nav/>

        <br/>

        {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

        <div className="container mb-6">
            <div className="col-lg-5 mx-auto">
                <div className="bg-green py-2 mb-3"/>
                <div className="bg-primary px-4 py-3">
                    <h2 className="mb-0 text-uppercase text-white">{t("Anmeldung")}</h2>
                </div>
                <div className="bg-secondary p-4 text-white">

                    <Formik
                        initialValues={{
                            username: '',
                            password: ''
                        }}
                        onSubmit={(values, actions) => {
                            actions.setStatus(undefined)
                            setErrors(undefined)

                            login({
                                variables: {
                                    ...values
                                }
                            }).catch(error => {
                                const [e, fe] = processFormResult(error.graphQLErrors)
                                actions.setStatus(fe)
                                setErrors(e)

                            }).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}>
                        {({
                              status,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                          }) => (

                            <Form onSubmit={handleSubmit}>
                                <fieldset className="pb-3">
                                    <div className="form-floating mb-4">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.username ? " is-invalid" : "")} id="username"
                                               autoComplete="username"
                                               onChange={handleChange} onBlur={handleBlur}
                                               placeholder="name@example.com" required/>
                                        <label htmlFor="username" className="text-white">{t("Benutzername oder E-Mail")}*</label>
                                        {status && status.username ? <div className="invalid-feedback">{t(status.username)}</div> : <></>}
                                    </div>
                                    <div className="form-floating">
                                        <input type="password" className={"form-control bg-transparent text-white border-white" + (status && status.password ? " is-invalid" : "")} id="password"
                                               autoComplete="current-password"
                                               placeholder="Passwort" onChange={handleChange}
                                               onBlur={handleBlur} required/>
                                        <label htmlFor="password" className="text-white">{t("Passwort")}*</label>
                                        {status && status.password ? <div className="invalid-feedback">{t(status.password)}</div> : <></>}
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-xxl-6 mt-3">
                                        <button type="submit" disabled={isSubmitting} className="btn btn-light w-100">{t("Anmelden")}</button>
                                        <p className="small mt-3">*{t("Benötigtes Feld")}</p>
                                    </div>
                                    <div className="col-xxl-6 mt-3">
                                        <Link to={REQUEST_NEW_PASSWORD_PAGE_URL}>{t("Passwort vergessen")}</Link><br/>
                                        <Link to={REGISTER_PAGE_URL}>{t("Registrieren")}</Link>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>

        <Footer/>
    </>);
}

export const LOGIN_PAGE_URL = "/login"

export default withRouter(LoginPage);
