import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./Nav";
import {gql, useMutation} from "@apollo/client";
import {Form, Formik} from "formik";
import {processFormResult} from "../graphql/type/ErrorExtension";
import {useHistory} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {REQUEST_NEW_PASSWORD_CONIRMATION_PAGE_URL} from "./RequestNewPasswordConfirmationPage";

const GQ_REQUEST_NEW_PASSWORD = gql`mutation requestNewPassword(
        $email: String! 
    ) {
        requestNewPassword(
            email: $email
        )
    }`;

interface RequestNewPasswordPageInput {
    email: string
}

interface RequestNewPasswordPageResponse {
    requestNewPassword: {
        id: string
    }
}

function RequestNewPasswordPage() {

    const {t} = useTranslation("RequestNewPasswordPage");

    const history = useHistory()

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const [register] = useMutation<RequestNewPasswordPageResponse, RequestNewPasswordPageInput>(GQ_REQUEST_NEW_PASSWORD, {
        onCompleted: () => {
            history.push(REQUEST_NEW_PASSWORD_CONIRMATION_PAGE_URL, {})
        },
    });

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "RequestNewPassword"})
    })

    return (<>
        <Header/>

        <Nav/>

        {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

        <div className="container mb-6">
            <div className="col-lg-5 mx-auto">
                <div className="bg-green py-2 mb-3"/>
                <div className="bg-primary px-4 py-3">
                    <h2 className="mb-0 text-uppercase text-white">Neues Passwort anfordern</h2>
                </div>
                <div className="bg-secondary p-4 text-white">

                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        onSubmit={(values, actions) => {
                            actions.setStatus(undefined)
                            setErrors(undefined)

                            register({
                                variables: {
                                    ...values
                                }
                            }).catch(error => {
                                const [e, fe] = processFormResult(error.graphQLErrors)
                                actions.setStatus(fe)
                                setErrors(e)

                            }).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}>
                        {({
                              status,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                          }) => (

                            <Form onSubmit={handleSubmit}>
                                <fieldset className="pb-3">
                                    <div className="form-floating mb-4">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.email ? " is-invalid" : "")} id="email"
                                               placeholder="email" autoComplete="email" required onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="email" className="text-white">{t("E-Mail")}*</label>
                                        {status && status.email ? <div className="invalid-feedback">{t(status.email)}</div> : <></>}
                                    </div>
                                </fieldset>
                                <div>
                                    <button type="submit" className="btn btn-light w-100" disabled={isSubmitting}>{t("Passwort anfordern")}</button>
                                    <p className="small mt-3">*{t("Benötigtes Feld")}</p>
                                </div>
                            </Form>

                        )}
                    </Formik>

                </div>
            </div>
        </div>

        <Footer/>
    </>)
}

export const REQUEST_NEW_PASSWORD_PAGE_URL = "/request_new_password"

export default RequestNewPasswordPage;
