import {gql, useQuery} from "@apollo/client";
import Header from "../../../Header";
import Footer from "../../../Footer";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import Nav from "../../Nav";
import {INCIDENTS_PAGE_URL} from "../IncidentsPage";
import {EDIT_COMMENT_PAGE_URL} from "./EditCommentPage";
import {DELETE_COMMENT_PAGE_URL} from "./DeleteCommentPage";
import {Incident} from "../../../../graphql/type/Incident";
import {getQueryParam} from "../../../../util/queryparam";

interface CommentsPageProps {
    id: string
}

const GQ = gql`query commentsPage($id: ID!) {
    getIncident(id: $id) {
        id
        allComments {
            id
            comment
            unlocked
        }
    }
}`;

interface CommentsPageRequest {
}

interface CommentsPageResponse {
    getIncident: Incident
}

const CommentsPage: React.FC<RouteComponentProps<{}, {}, CommentsPageProps>> = (props) => {
    const {t} = useTranslation("admin/incident/comment/CommentsPage");

    const {loading, error, data} = useQuery<CommentsPageResponse, CommentsPageRequest>(GQ, {
        variables: {
            id: props.location.state?.id
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="container mb-5">
                    <Link to={INCIDENTS_PAGE_URL}>Zurück</Link>
                </div>

                <div className="container mb-3">
                    <h1>{t("Kommentare")}</h1>
                </div>

                <table className="table">

                    <thead>
                    <tr>
                        <th>Kommentar</th>
                        <th>Freigeschaltet</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        // der slice() Aufruf wird benötigt, um das Array zu kopieren, da sonst sortierung nicht möglich ist
                        data?.getIncident?.allComments?.map((it) => (<tr key={it.id}>
                            <td>{it.comment}</td>
                            <td>{it.unlocked ? "ja" : "nein"}</td>
                            <td><Link to={{pathname: EDIT_COMMENT_PAGE_URL, state: {id: it.id}}}>Bearbeiten</Link></td>
                            <td><Link to={{pathname: DELETE_COMMENT_PAGE_URL, state: {id: it.id}}}>Löschen</Link></td>
                        </tr>))
                    }

                    </tbody>

                </table>

            </div>

            <Footer/>
        </>
    );
}
export const COMMENTS_PAGE_URL = "/admin/incidents/incident/comments"

export default withRouter(CommentsPage);
