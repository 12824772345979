import React from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Nav from "./Nav";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {HOME_PAGE_URL} from "./HomePage";
import Footer from "./Footer";

interface RegisterConfirmationPageProps {
}

const RegisterConfirmationPage: React.FC<RouteComponentProps<{}, {}, RegisterConfirmationPageProps>> = (props) => {

    const {t} = useTranslation("RegisterConfirmationPage");

    const history = useHistory()

    if (!props.location.state) {
        history.push(HOME_PAGE_URL)
    }

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "RegisterConfirmationPage"})
    })

    return (<>
        <Header/>

        <Nav/>

        <div className="container">

            <div className="mt-5 mb-4">
                <h1>{t("Vielen Dank für Ihre Registrierung!")}</h1>
            </div>

            <p>{t("absatz1")}</p>

            <p><Link to={HOME_PAGE_URL}>{t("Zurück zur Startseite")}</Link></p>

        </div>

        <Footer/>
    </>);
}

export const REGISTER_CONFIRMATION_PAGE_URL = "/register_confirmation"

export default withRouter(RegisterConfirmationPage);
