import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Footer from "./Footer"
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import Nav from "./Nav";
import {gql, useMutation} from "@apollo/client";
import * as QueryString from "query-string";
import {Form, Formik, Field} from "formik";
import {processFormResult} from "../graphql/type/ErrorExtension";
import {SET_PASSWORD_CONFIRMATION_PAGE} from "./SetPasswordConfirmationPage";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import * as Yup from "yup";

const GQ_SET_PASSWORD = gql`mutation setPassword($authCode: String!, $password: String!) {
    setPassword(authCode: $authCode, password: $password)
}`;

interface SetPasswordInput {
    authCode: string
    password: string
}

const SetPasswordPage = (props: RouteComponentProps<{}, {}, {}>) => {

    const [setPassword] = useMutation<{ setPassword: Boolean }, SetPasswordInput>(GQ_SET_PASSWORD, {
        onCompleted: () => {
            history.push(SET_PASSWORD_CONFIRMATION_PAGE, {})
        },
    });

    const history = useHistory();

    const {t} = useTranslation("SetPasswordPage");

    const query: string[] | string | null = QueryString.parse(props.location.search)[SET_PASSWORD_PAGE_PARAMETER_AUTH_CODE];
    const authCode: string | null = (Array.isArray(query)) ? query[0] : query;

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const {trackPageView} = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "SetPasswordPage"})
    })

    return (<>
        <Header/>

        <Nav/>

        <div className="container">
            <div className=" mt-5 mb-4">
                <h1>{t("Passwort setzen")}</h1>
            </div>
            
            {!!authCode ? <>
                {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

                <p>{t("absatz1")}</p>

                <Formik
                    initialValues={{
                        authCode: authCode,
                        password: '',
                        passwordConfirmation: '',
                    }}
                    validationSchema={
                        Yup.object().shape({
                            passwordConfirmation: Yup.string()
                                .oneOf([Yup.ref("password")], t("password must match"))
                        })
                    }
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        setPassword({
                            variables: {
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          errors,
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (

                        <Form onSubmit={handleSubmit}>
                            <div className="row gx-6">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="password">{t("Passwort")}</label>
                                        <Field type="password" className={"form-control" + (status?.password || errors?.password ? " is-invalid" : "")} id="password" name="password"/>
                                        {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                        {status?.password ? <div className="invalid-feedback">{t(status.password)}</div> : <></>}
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="mb-3">
                                        <label htmlFor="passwordConfirmation">{t("Passwort bestätigen")}</label>
                                        <Field type="password" className={"form-control" + (status?.passwordConfirmation || errors?.passwordConfirmation ? " is-invalid" : "")}
                                               id="passwordConfirmation" name="passwordConfirmation"/>
                                        {status?.passwordConfirmation ? <div className="invalid-feedback">{t(status.passwordConfirmation)}</div> : <></>}
                                        {errors?.passwordConfirmation ? <div className="invalid-feedback">{t(errors.passwordConfirmation)}</div> : <></>}
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-light" disabled={isSubmitting}>{t("Passwort speichern")}</button>
                        </Form>
                    )}
                </Formik>
            </> : <>
                <p>Invalid auth Code</p>
            </>}

        </div>

        <Footer/>
    </>);
}

export const SET_PASSWORD_PAGE = "/set_password"
export const SET_PASSWORD_PAGE_PARAMETER_AUTH_CODE = "authCode"

export default withRouter(SetPasswordPage);
