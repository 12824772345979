import React from "react";
import imgBmbfLogo from "./_img/bmbf-logo.jpg"
import imgUkjLogo from "./_img/ukj-logo.png"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IMPRINT_PAGE_URL} from "./ImprintPage";
import {PRIVACY_STATEMENT_PAGE_URL} from "./PrivacyStatementPage";

function Footer() {

    const {t} = useTranslation("Footer");

    return (
        <div id="footer" className="container py-4">
            <div className="row align-items-center">
                <div className="col-md-8">
                    <nav className="nav mb-3">
                        <Link to={IMPRINT_PAGE_URL} className="nav-link">{t("Impressum")}</Link>
                        <Link to={PRIVACY_STATEMENT_PAGE_URL} className="nav-link">{t("Datenschutz")}</Link>
                        {/*<Link to={ACCESSIBILITY_PAGE_URL} className="nav-link">{t("Barrierefreiheit")}</Link>*/}
                    </nav>
                    <p id="copyright">© 2021 {t("Stabsstelle Tierschutz")} | {t("Universitätsklinikum Jena")} | Bachstraße 18 | 07743 Jena</p>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-6 p-3"><img src={imgUkjLogo} className="img-fluid" alt=""/></div>
                        <div className="col-6 p-3"><img src={imgBmbfLogo} className="img-fluid" alt=""/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;