import React from "react";
import LanguageSelect from "./../LanguageSelect";
import {NavLink} from "react-router-dom"
import {INCIDENTS_PAGE_URL} from "./IncidentsPage";
import {REPORT_INCIDENT_PAGE_URL} from "../ReportIncidentPage";
import {HOME_PAGE_URL} from "../HomePage";
import {Logout} from "../Logout";
import {useTranslation} from "react-i18next";

function Nav() {

    const {t} = useTranslation("database/Nav")

    return (
        <nav id="navigation" className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container flex-row-reverse">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                <LanguageSelect/>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav justify-content-between w-100 pe-md-6">
                        <li className="nav-item">
                            <NavLink to={HOME_PAGE_URL} className="nav-link">{t("Startseite")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={INCIDENTS_PAGE_URL} className="nav-link">{t("Falldatenbank")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={REPORT_INCIDENT_PAGE_URL} className="nav-link">{t("Fall berichten")}</NavLink>
                        </li>
                        <Logout/>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Nav;
