import {createInstance} from "@datapunt/matomo-tracker-react";
import {gql} from "@apollo/client";
import {MatomoInstance} from "@datapunt/matomo-tracker-react/lib/types";
import client from "../../graphql/ApolloClient";

const GQ = gql`query Matomo {
    getConfig {
        id
        matomoSideId
    }
}`;

interface MatomoRequest {
    getConfig: {
        matomoSideId?: number
    }
}

export const getMatomoSideId = (): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
        client.query<MatomoRequest>({
            query: GQ
        })
            .then((result) => result.data.getConfig.matomoSideId ? resolve(result.data.getConfig.matomoSideId) : reject("no matomoSideId resolved"))
            .catch((reason) => reject(reason))
    })
}

const createMatomoInstance = (siteId: number): MatomoInstance => {
    return createInstance({
        urlBase: 'https://matomo.cirs-las.org/',
        siteId: siteId,
        // userId: 'UID76903202', // optional, default value: `undefined`.
        // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
        // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
        disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
        // heartBeat: { // optional, enabled by default
        //     active: true, // optional, default value: true
        //     seconds: 10 // optional, default value: `15
        // },
        // linkTracking: false, // optional, default value: true
        configurations: { // optional, default value: {}
            // any valid matomo configuration, all below are optional
            // disableCookies: true,
            // setSecureCookie: true,
            // setRequestMethod: 'POST'
        }
    })
}

const matomo = (): Promise<MatomoInstance | undefined> => {
    return new Promise<MatomoInstance | undefined>((resolve, reject) => {
        getMatomoSideId()
            .then((result) => {
                console.log("matomosideid: " +  result)
                resolve(createMatomoInstance(result))
            })
            .catch((reason) => reject(reason))
    })
}

export default matomo
