import React from "react";
import LanguageSelect from "./LanguageSelect";
import {NavLink} from "react-router-dom";
import {HOME_PAGE_URL} from "./HomePage";
import {PROJECT_ROUTER_URL} from "./project/ProjectRouter";
import {INCIDENTS_PAGE_URL} from "./database/IncidentsPage";
import {Logout} from "./Logout";
import {useTranslation} from "react-i18next";
import {gql, useQuery} from "@apollo/client";
import {User} from "../graphql/type/User";
import {ADMIN_HOME_PAGE_URL} from "./admin/AdminHomePage";
import {LOGIN_PAGE_URL} from "./LoginPage";

const GQ = gql`query Nav {
    currentUser {
        person {
            admin
        }
    }
}`;

interface NavResponse {
    currentUser: User
}

function Nav() {

    const {loading, error, data} = useQuery<NavResponse>(GQ);

    const {t} = useTranslation("Nav");

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    return (
        <nav id="navigation" className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container flex-row-reverse">
                <LanguageSelect/>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav justify-content-between w-100 pe-md-6">
                        <li className="nav-item">
                            <NavLink to={HOME_PAGE_URL} className="nav-link">{t("Startseite")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={PROJECT_ROUTER_URL} className="nav-link">{t("Projekt")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={INCIDENTS_PAGE_URL} className="nav-link">{t("Falldatenbank")}</NavLink>
                        </li>
                        {data?.currentUser?.person?.admin === true ?
                            <li className="nav-item">
                                <NavLink to={ADMIN_HOME_PAGE_URL} className="nav-link">{t("Administration")}</NavLink>
                            </li> : <></>}
                        {!data?.currentUser ?
                            <li className="nav-item">
                                <NavLink to={LOGIN_PAGE_URL} className="nav-link">{t("Anmelden")}</NavLink>
                            </li> : <></>}
                        <Logout/>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Nav;
