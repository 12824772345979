import React, {useState} from "react";
import Header from "./../Header"
import Footer from "./../Footer"
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import Nav from "./Nav";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {Incident} from "../../graphql/type/Incident";
import {INCIDENTS_PAGE_URL} from "./IncidentsPage";
import i18n from "i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {Form, Formik, Field} from 'formik';
import {processFormResult} from "../../graphql/type/ErrorExtension";

interface IncidentPageProps {
    id: string
}

const GQ = gql`query incidentPage($id: ID!, $language: String!) {
    getIncident(id: $id) {
        id
        
        title(language: $language)
        tags(language: $language)
     
        negativeExperience(language: $language)
        backgroundInformation(language: $language)
        reasons(language: $language)
        
        animalAgeGroup {
            caption(language: $language)
        }
        animalGender {
            caption(language: $language)
        }
        animalHygieneStatus {
            caption(language: $language)
        }
        animalPregnantStatus {
            caption(language: $language)
        }
        animalSpecies {
            caption(language: $language)
        }
        incidentContext {
            caption(language: $language)
        }
        incidentContributionFactors {
            caption(language: $language)
        }
        incidentDegreeOfDistress {
            caption(language: $language)
        }
        incidentDisciplines {
            caption(language: $language)
        }
        incidentExperimentResultUsability {
            caption(language: $language)
        }
        incidentFrequency {
            caption(language: $language)
        }
     
        periodFrom,
        periodTo,
     
        reporterType {
            caption(language: $language)
        }
        
        unlockedComments {
            id
            comment
        }
    }
}`

interface IncidentPageRequest {
    id: string
    language: string
}

interface IncidentPageResponse {
    getIncident: Incident
}

const GQ_CREATE_COMMENT = gql`mutation createComment(
    $incidentId: ID!,
    $comment: String!
) {
    createComment(
        incidentId: $incidentId,
        comment: $comment
    ) {
        id
    }
}`;

interface CreateCommentRequest {
    incidentId: string
    comment: string
}

interface CreateCommentResponse {
    id: string
}

const IncidentPage: React.FC<RouteComponentProps<{}, {}, IncidentPageProps>> = (props) => {

    const {t} = useTranslation("database/IncidentPage");

    const gq = useQuery<IncidentPageResponse, IncidentPageRequest>(GQ, {
        variables: {
            id: props.location.state?.id,
            language: i18n.language
        },
    });

    const [gqCreateComment] = useMutation<CreateCommentResponse, CreateCommentRequest>(GQ_CREATE_COMMENT);

    const [kommentarPosted, setKommentarPosted] = useState(false)

    const {trackPageView} = useMatomo()

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "IncidentPage"})
    })

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">
                <div className="mt-5 mb-4">
                    <Link to={INCIDENTS_PAGE_URL}>{t("Zurück")}</Link>
                </div>

                <div className="mb-4">
                    <h1>{gq.data?.getIncident?.title}</h1>
                </div>

                {gq.data?.getIncident?.backgroundInformation ? <div className="mb-3">
                    <h2>{t("Hintergrundinformation")}</h2>
                    <p>{gq.data?.getIncident?.backgroundInformation}</p>
                </div> : <></>}
                {gq.data?.getIncident?.negativeExperience ? <div className="mb-3">
                    <h2>{t("Negativerfahrung")}</h2>
                    <p>{gq.data?.getIncident?.negativeExperience}</p>
                </div> : <></>}
                {gq.data?.getIncident?.reasons ? <div className="mb-3">
                    <h2>{t("Ursachen und Verbesserungsmaßnahmen")}</h2>
                    <p>{gq.data?.getIncident?.reasons}</p>
                </div> : <></>}

                {/* <div className="my-6 py-4 border-top border-2 border-primary">
                    <h2>Comments</h2>
                    <h3>Max Mustermann</h3>
                    <p className="text-black-50">23rd February 2021 at 3:38pm <span className="ps-3"><i className="bi bi-pencil-fill"/> Edit</span></p>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                        ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>
                </div>*/}

                <div className="mb-3">
                    <h2>{t("Tier")}</h2>
                    <div className="row gx-6">
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Tierart")}: {gq.data?.getIncident.animalSpecies?.caption}
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Alter")}: {gq.data?.getIncident.animalAgeGroup?.caption}
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Geschlecht")}: {gq.data?.getIncident.animalGender?.caption}
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Tragend")}: {gq.data?.getIncident.animalPregnantStatus?.caption}<br/>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Hygienestatus")}: {gq.data?.getIncident.animalHygieneStatus?.caption}<br/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <h2>{t("Vorfall")}</h2>
                    <div className="row gx-6">
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Themengebiete")}: {gq.data?.getIncident.incidentDisciplines?.map((it) => it.caption).join("; ")}<br/>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Kontext")}: {gq.data?.getIncident.incidentContext?.caption}<br/>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Faktoren")}: {gq.data?.getIncident.incidentContributionFactors?.map((it) => it.caption).join("; ")}<br/>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-6">
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Belastungsgrad")}: {gq.data?.getIncident.incidentDegreeOfDistress?.caption}<br/>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Verwertbarkeit")}: {gq.data?.getIncident.incidentExperimentResultUsability?.caption}<br/>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="mb-3">
                                {t("Häufigkeit")}: {gq.data?.getIncident.incidentFrequency?.caption}<br/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <h2>{t("Berichtende Person")}</h2>
                    <div className="row gx-6">
                        <div className="col-md">
                            <div className="mb-3">
                                {gq.data?.getIncident.reporterType?.caption}<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!kommentarPosted ?
                <Formik
                    initialValues={{
                        comment: ''
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqCreateComment({
                            variables: {
                                incidentId: props.location.state.id,
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                        setKommentarPosted(true)
                    }}>{({status, handleSubmit, isSubmitting}) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="fluid-container">
                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Kommentar abgeben")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="comment">{t("Kommentar")}</label>
                                                <Field as="textarea" type="text" className={"form-control" + (status && status.comment ? " is-invalid" : "")}
                                                       id="comment" name="comment"/>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>{t("Abschicken")}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Form>
                )}

                </Formik> :
                <form>
                    <div className="fluid-container">
                        <fieldset className="bg-lightblue">
                            <div className="container">
                                <hr/>
                                <legend>{t("Kommentar übermittelt")}</legend>
                                <p>{t("Kommentar_abgesendet")}</p>
                            </div>
                        </fieldset>
                    </div>
                </form>
            }

            {gq.data?.getIncident.unlockedComments?.length && gq.data?.getIncident.unlockedComments?.length > 0 ?
            <div className="fluid-container">
                <form>
                <fieldset>
                    <div className="container">
                        <hr/>
                        <legend>{t("Kommentare")}</legend>

                        <div className="table-responsive mt-3">
                            <table className="table table-hover">
                                <tbody>
                                {gq.loading ? <p>Loading...</p> : <>{gq.data?.getIncident.unlockedComments?.map((it) => (
                                    <tr key={it.id}>
                                        <td>{it.comment}</td>
                                    </tr>
                                ))}</>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
                </form>
            </div> : <></> }

            <Footer/>
        </>
    );
}

export const INCIDENT_PAGE_URL = "/database/incidents/incident"

export default withRouter(IncidentPage);
