import React from "react";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./Nav";
import {Trans, useTranslation} from "react-i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {gql, useQuery} from "@apollo/client";

const GQ = gql`query privacyStatementPage {
    getConfig {
        id
        baseUrl
        emailPublic
    }
}`;

interface PrivacyStatementPageResponse {
    getConfig: {
        baseUrl: String
        emailPublic: String
    }
}

function PrivacyStatementPage() {

    const {loading, error, data} = useQuery<PrivacyStatementPageResponse>(GQ);

    const {t} = useTranslation("PrivacyStatementPage");

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "PrivacyStatementPage"})
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="mt-5 mb-4">
                    <h1>{t("Datenschutzerklärung")}</h1>
                </div>

                <div className="mt-5 mb-4">
                    <h2>{t("Grundlegendes")}</h2>
                </div>

                <p>{t("absatz1_1")}</p>
                <p><Trans t={t} i18nKey="absatz1_2" tOptions={
                    {
                        email: data?.getConfig.emailPublic,
                        url: data?.getConfig.baseUrl
                    }
                }><a href={"https://" + data?.getConfig.baseUrl}>XXX</a><br/><a href={"mailto://" + data?.getConfig.emailPublic}>XXX</a></Trans></p>
                <p>{t("absatz1_3")}</p>
                <p>{t("absatz1_4")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Verschlüsselte Verbindung")}</h2>
                </div>

                <p><Trans t={t} i18nKey="absatz2"><a href="https://www.hetzner.de">XXX</a><a href="https://www.hetzner.de/unternehmen/zertifizierung">XXX</a></Trans></p>

                <div className="mt-5 mb-4">
                    <h2>{t("Zugriffsdaten")}</h2>
                </div>

                <p>{t("absatz3_1")}</p>
                <ul>
                    <li>{t("absatz3_1_1")}</li>
                    <li>{t("absatz3_1_2")}</li>
                    <li>{t("absatz3_1_3")}</li>
                    <li>{t("absatz3_1_4")}</li>
                    <li>{t("absatz3_1_5")}</li>
                    <li>{t("absatz3_1_6")}</li>
                    <li>{t("absatz3_1_7")}</li>
                </ul>
                <p>{t("absatz3_2")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Cookies/Browser local storage")}</h2>
                </div>

                <p>{t("absatz4_1")}</p>
                <p>{t("absatz4_2")}</p>
                <p>{t("absatz4_3")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Erfassung und Verarbeitung personenbezogener Daten")}</h2>
                </div>

                <p>{t("absatz5_1")}</p>
                <p>{t("absatz5_2")}</p>
                <p>{t("absatz5_3")}</p>
                <ul>
                    <li>{t("absatz5_4_1")}</li>
                    <li>{t("absatz5_4_2")}</li>
                    <li>{t("absatz5_4_3")}</li>
                </ul>

                <div className="mt-5 mb-4">
                    <h2>{t("Umgang mit Kontaktdaten")}</h2>
                </div>

                <p>{t("absatz6")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Umgang mit Kommentaren und Beiträgen")}</h2>
                </div>

                <p>{t("absatz7")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Einsatz einer Webanalyse-Software")}</h2>
                </div>

                <p>{t("absatz8_1")}</p>
                <p>{t("absatz8_2")}</p>
                <ul>
                    <li>{t("absatz8_3_1")}</li>
                    <li>{t("absatz8_3_2")}</li>
                    <li>{t("absatz8_3_3")}</li>
                    <li>{t("absatz8_3_4")}</li>
                    <li>{t("absatz8_3_5")}</li>
                    <li>{t("absatz8_3_6")}</li>
                    <li>{t("absatz8_3_7")}</li>
                    <li>{t("absatz8_3_8")}</li>
                    <li>{t("absatz8_3_9")}</li>
                    <li>{t("absatz8_3_10")}</li>
                    <li>{t("absatz8_3_11")}</li>
                    <li>{t("absatz8_3_12")}</li>
                    <li>{t("absatz8_3_13")}</li>
                    <li>{t("absatz8_3_14")}</li>
                    <li>{t("absatz8_3_15")}</li>
                </ul>
                <p>{t("absatz8_4")}</p>
                <p>{t("absatz8_5")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Rechte des Nutzers")}</h2>
                </div>

                <p>{t("absatz9")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Löschung von Daten")}</h2>
                </div>

                <p>{t("absatz10")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Widerspruchsrecht")}</h2>
                </div>

                <p>{t("absatz11")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Zustimmung")}
                    </h2>
                </div>

                <p>{t("absatz12")}</p>

            </div>

            <Footer/>
        </>
    );
}

export const PRIVACY_STATEMENT_PAGE_URL = "/privacyStatement"

export default PrivacyStatementPage;
