import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import NewsListPage, {NEWS_LIST_PAGE_URL} from "./NewsListPage";
import NewsPage, {NEWS_PAGE_URL} from "./NewsPage";

function NewsRouter() {

    return (
        <Switch>
            <Route exact path={NEWS_LIST_PAGE_URL}><NewsListPage/></Route>
            <Route exact path={NEWS_PAGE_URL}><NewsPage/></Route>
            <Redirect to={NEWS_LIST_PAGE_URL}/>
        </Switch>
    )
}

export const NEWS_ROUTER_URL = "/project/news"

export default NewsRouter;
