import React from "react";
import Header from "./../../Header"
import Footer from "./../../Footer"
import Nav from "../Nav";
import {useMatomo} from "@datapunt/matomo-tracker-react";

function NewsListPage() {

    // const {t} = useTranslation("project/news/NewsListPage");

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "EventsPage"})
    })

    return (
        <>
            <Header/>

            <Nav/>

            <p>Neue Meldungen</p>

            <Footer/>
        </>
    );
}

export const NEWS_LIST_PAGE_URL="/project/news"

export default NewsListPage;
