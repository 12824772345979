import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import HomePage, {HOME_PAGE_URL} from "./HomePage";
import PrivacyStatementPage, {PRIVACY_STATEMENT_PAGE_URL} from "./PrivacyStatementPage";
import AccessibilityPage, {ACCESSIBILITY_PAGE_URL} from "./AccessibilityPage";
import ImprintPage, {IMPRINT_PAGE_URL} from "./ImprintPage";
import AdminRouter, {ADMIN_ROUTER_URL} from "./admin/AdminRouter";
import ProjectRouter, {PROJECT_ROUTER_URL} from "./project/ProjectRouter";
import DatabaseRouter, {DATABASE_ROUTER_URL} from "./database/DatabaseRouter";
import ReportIncidentPage, {REPORT_INCIDENT_PAGE_URL} from "./ReportIncidentPage";
import LoginPage, {LOGIN_PAGE_URL} from "./LoginPage";
import RegisterPage, {REGISTER_PAGE_URL} from "./RegisterPage";
import PrivateRoute from "./PrivateRoute";
import SetPasswordPage, {SET_PASSWORD_PAGE} from "./SetPasswordPage";
import RegisterConfirmationPage, {REGISTER_CONFIRMATION_PAGE_URL} from "./RegisterConfirmationPage";
import ReportIncidentConfirmationPage, {REPORT_INCIDENT_CONFIRMATION_PAGE_URL} from "./ReportIncidentConfirmationPage";
import SetPasswordConfirmationPage, {SET_PASSWORD_CONFIRMATION_PAGE} from "./SetPasswordConfirmationPage";
import RequestNewPasswordPage, {REQUEST_NEW_PASSWORD_PAGE_URL} from "./RequestNewPasswordPage";
import RequestNewPasswordConfirmationPage, {REQUEST_NEW_PASSWORD_CONIRMATION_PAGE_URL} from "./RequestNewPasswordConfirmationPage";

const DefaultRouter = () => {
    return (
        <Switch>
            <Route exact path={HOME_PAGE_URL}><HomePage/></Route>
            <Route exact path={REPORT_INCIDENT_PAGE_URL}><ReportIncidentPage/></Route>
            <Route exact path={REPORT_INCIDENT_CONFIRMATION_PAGE_URL}><ReportIncidentConfirmationPage/></Route>
            <Route exact path={PRIVACY_STATEMENT_PAGE_URL}><PrivacyStatementPage/></Route>
            <Route exact path={ACCESSIBILITY_PAGE_URL}><AccessibilityPage/></Route>
            <Route exact path={IMPRINT_PAGE_URL}><ImprintPage/></Route>
            <Route exact path={LOGIN_PAGE_URL}><LoginPage/></Route>
            <Route exact path={REGISTER_PAGE_URL}><RegisterPage/></Route>
            <Route exact path={REGISTER_CONFIRMATION_PAGE_URL}><RegisterConfirmationPage/></Route>
            <Route exact path={REQUEST_NEW_PASSWORD_PAGE_URL}><RequestNewPasswordPage/></Route>
            <Route exact path={REQUEST_NEW_PASSWORD_CONIRMATION_PAGE_URL}><RequestNewPasswordConfirmationPage/></Route>
            <Route exact path={SET_PASSWORD_PAGE}><SetPasswordPage/></Route>
            <Route exact path={SET_PASSWORD_CONFIRMATION_PAGE}><SetPasswordConfirmationPage/></Route>
            <Route path={PROJECT_ROUTER_URL}><ProjectRouter/></Route>
            <PrivateRoute path={DATABASE_ROUTER_URL}><DatabaseRouter/></PrivateRoute>
            <PrivateRoute path={ADMIN_ROUTER_URL}><AdminRouter/></PrivateRoute>
            <Redirect to={HOME_PAGE_URL}/>
        </Switch>
    );
}

export default DefaultRouter;