import React from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Footer from "./Footer"
import NewsComponent from "./NewsComponent"
import imgGrafikHelfenSieMitDe from "./_img/grafik-helfen-sie-mit_de.svg"
import imgGrafikHelfenSieMitEn from "./_img/grafik-helfen-sie-mit_en.svg"
import imgGrafikHelfenSieMitFr from "./_img/grafik-helfen-sie-mit_fr.svg"
import imgMaus1 from "./_img/mouse-01.jpg"
import imgMaus2 from "./_img/mouse-02.jpg"
import imgMaus3 from "./_img/mouse-03.jpg"
import imgMaus4 from "./_img/mouse-04.jpg"
import {Link} from "react-router-dom";
import {REPORT_INCIDENT_PAGE_URL} from "./ReportIncidentPage";
import {DATABASE_ROUTER_URL} from "./database/DatabaseRouter";
import Nav from "./Nav";
import {useMatomo} from "@datapunt/matomo-tracker-react";


function HomePage() {

    const {i18n, t} = useTranslation("HomePage");

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "HomePage"})
    })

    return (<>
        <Header/>

        <Nav/>

        <div className="container mt-5 mb-4">
            <h1>{t("CIRS-LAS")}</h1>
        </div>

        <div className="container mb-6">
            <div className="row">
                <div className="col-lg-7 pe-md-5">
                    <p className="text-primary fst-italic">{t("absatz1")}</p>
                    <p>{t("absatz2")}</p>
                    <p><span className="highlight">{t("absatz3_1")}</span> {t("absatz3_2")}</p>
                    <p><span className="highlight">{t("absatz4_1")}</span> {t("absatz4_2")}</p>
                    <p><span className="highlight">{t("absatz5_1")}</span> {t("absatz5_2")}</p>
                </div>
                <div className="col-lg-5">
                    <div className="bg-green py-2 mb-3"/>
                    <div className="bg-primary px-4 py-3">
                        <h2 className="mb-0 text-uppercase text-white">{t("Falldatenbank")}</h2>
                    </div>
                    <div className="bg-secondary p-4 text-white">
                        <p>{t("Falldatenbank_1")}</p>
                        <div className="row">
                            <div className="col-xxl-6 mt-3">
                                <Link to={DATABASE_ROUTER_URL} className="btn btn-light w-100">{t("Falldatenbank_Fallrecherche")}</Link>
                            </div>
                            <div className="col-xxl-6 mt-3">
                                <Link to={REPORT_INCIDENT_PAGE_URL} className="btn btn-light w-100">{t("Falldatenbank_FallEintragen")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="bg-green py-2 mt-3"/>
                </div>
            </div>
        </div>

        <div className="container-fluid bg-secondary">
            <div className="container py-6">
                <h2 className="text-uppercase text-white mb-5">{t("Funktionsweise")}</h2>
                <div className="row g-6">
                    <div className="col-lg-7">
                        <img className="img-fluid" src={i18n.language === "fr" ? imgGrafikHelfenSieMitFr : i18n.language === "de" ? imgGrafikHelfenSieMitDe : imgGrafikHelfenSieMitEn} alt=""/>
                    </div>
                    <div className="col-lg-5">
                        <NewsComponent/>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid p-0 border-top border-10 border-green">
            <div className="row g-0">
                <div className="col-3"><img src={imgMaus1} className="img-fluid" alt="..."/></div>
                <div className="col-3"><img src={imgMaus2} className="img-fluid" alt="..."/></div>
                <div className="col-3"><img src={imgMaus3} className="img-fluid" alt="..."/></div>
                <div className="col-3"><img src={imgMaus4} className="img-fluid" alt="..."/></div>
            </div>
        </div>

        <Footer/>
    </>);
}

export const HOME_PAGE_URL = "/home"

export default HomePage;
