import React from "react";
import {useTranslation} from "react-i18next";
import imgFlagDe from "./_img/flag-de.svg"
import imgFlagEn from "./_img/flag-en.svg"
import imgFlagFr from "./_img/flag-fr.svg"

function LanguageSelect() {

    const {i18n, t} = useTranslation("LanguageSelect");

    function switchLanguage(e: React.MouseEvent, l: string) {
        i18n.changeLanguage(l);
    }

    return (
        <ul id="languages" className="nav justify-content-end">
            <li className="nav-item active">
                <button className="btn link nav-link" onClick={(e: React.MouseEvent) => {
                    switchLanguage(e, "de")
                }}>
                    <img className="language-flag" alt={t("Deutsch")} title={t("Deutsch")} src={imgFlagDe} width="48" height="30"/>
                </button>
            </li>
            <li className="nav-item">
                <button className="btn link nav-link" onClick={(e: React.MouseEvent) => {
                    switchLanguage(e, "en")
                }}>
                    <img className="language-flag" alt={t("English")} title={t("English")} src={imgFlagEn} width="48" height="30"/>
                </button>
            </li>
            <li className="nav-item">
                <button className="btn link nav-link" onClick={(e: React.MouseEvent) => {
                    switchLanguage(e, "fr")
                }}>
                    <img className="language-flag" alt={t("Français")} title={t("Français")} src={imgFlagFr} width="48" height="30"/>
                </button>
            </li>
        </ul>
    );
}

export default LanguageSelect;