import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {News} from "../../../graphql/type/News";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Field, Formik} from 'formik';
import {NEWS_LIST_PAGE_URL} from "./NewsListPage";
import {useTranslation} from "react-i18next";
import Nav from "../Nav";
import {processFormResult} from "../../../graphql/type/ErrorExtension";

interface EditNewsPageProps {
    id: string
}

const GQ = gql`query editEventPage($id: ID!) {
    getNews(id: $id) {
        id
        titleGerman
        titleEnglish
        titleFrench
        subtitleGerman
        subtitleEnglish
        subtitleFrench
        descriptionGerman
        descriptionEnglish
        descriptionFrench
        from
        to
    }
}`;

interface EditNewsPageRequest {
    id: string
}

interface EditNewsPageReponse {
    getNews: News
}

const GQ_CREATE_OR_UPDATE_NEWS = gql`
  mutation createOrUpdateNews(
      $id: ID,
      $titleGerman: String,
      $titleEnglish: String,
      $titleFrench: String,
      $subtitleGerman: String,
      $subtitleEnglish: String,
      $subtitleFrench: String,
      $descriptionGerman: String,
      $descriptionEnglish: String,
      $descriptionFrench: String,
      $from: Date,
      $to: Date
  ) {
    createOrUpdateNews(
      id: $id,
      titleGerman: $titleGerman,
      titleEnglish: $titleEnglish,
      titleFrench: $titleFrench,
      subtitleGerman: $subtitleGerman,
      subtitleEnglish: $subtitleEnglish,
      subtitleFrench: $subtitleFrench,
      descriptionGerman: $descriptionGerman,
      descriptionEnglish: $descriptionEnglish,
      descriptionFrench: $descriptionFrench,
      from: $from,
      to: $to
    ) {
      id
    }
  }
`;

const EditNewsPage: React.FC<RouteComponentProps<{}, {}, EditNewsPageProps>> = (props) => {

    const {t} = useTranslation("admin/news/EditNewsPage");

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<EditNewsPageReponse, EditNewsPageRequest>(GQ, {
        variables: {
            id: props.location.state?.id
        },
        skip: props.location.state?.id == null
    });

    const [createOrEditNews] = useMutation(GQ_CREATE_OR_UPDATE_NEWS, {
        onCompleted: () => {
            history.push(NEWS_LIST_PAGE_URL)
        },
        refetchQueries: [
            {
                query: gql`query getAllNews {
                    getAllNews {
                        id
                        title(language: "de")
                        subtitle(language: "de")
                        description(language: "de")
                        titleGerman
                        titleEnglish
                        titleFrench
                        subtitleGerman
                        subtitleEnglish
                        subtitleFrench
                        descriptionGerman
                        descriptionEnglish
                        descriptionFrench
                        from
                        to
                    }
                }`
            },
            {
                query: gql`query getAllNews {
                    getAllNews {
                        id
                        title(language: "en")
                        subtitle(language: "en")
                        description(language: "en")
                        titleGerman
                        titleEnglish
                        titleFrench
                        subtitleGerman
                        subtitleEnglish
                        subtitleFrench
                        descriptionGerman
                        descriptionEnglish
                        descriptionFrench
                        from
                        to
                    }
                }`
            },
            {
                query: gql`query getAllNews {
                    getAllNews {
                        id
                        title(language: "fr")
                        subtitle(language: "fr")
                        description(language: "fr")
                        titleGerman
                        titleEnglish
                        titleFrench
                        subtitleGerman
                        subtitleEnglish
                        subtitleFrench
                        descriptionGerman
                        descriptionEnglish
                        descriptionFrench
                        from
                        to
                    }
                }`
            },
            {
                query: gql`query getNews {
                    getNewsList(limit: 3) {
                        id
                    }
                }`
            }
        ]
    });

    const history = useHistory()

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={NEWS_LIST_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("News")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        titleGerman: gq.data?.getNews?.titleGerman,
                        titleEnglish: gq.data?.getNews?.titleEnglish,
                        titleFrench: gq.data?.getNews?.titleFrench,
                        subtitleGerman: gq.data?.getNews?.subtitleGerman,
                        subtitleEnglish: gq.data?.getNews?.subtitleEnglish,
                        subtitleFrench: gq.data?.getNews?.subtitleFrench,
                        descriptionGerman: gq.data?.getNews?.descriptionGerman,
                        descriptionEnglish: gq.data?.getNews?.descriptionEnglish,
                        descriptionFrench: gq.data?.getNews?.descriptionFrench,
                        from: gq.data?.getNews?.from,
                        to: gq.data?.getNews?.to
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        createOrEditNews({
                            variables: {
                                id: props.location.state?.id,
                                ...values
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Titel")}</legend>

                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="titleGerman">{t("Deutsch")}</label>
                                                <Field className={"form-control" + (status && status.titleGerman ? " is-invalid" : "")} id="titleGerman" name="titleGerman" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.titleGerman ? <div className="invalid-feedback">{t(status.titleGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="titleEnglish">{t("Englisch")}</label>
                                                <Field className={"form-control" + (status && status.titleEnglish ? " is-invalid" : "")} id="titleEnglish" name="titleEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.titleEnglish ? <div className="invalid-feedback">{t(status.titleEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="titleFrench">{t("Französisch")}</label>
                                                <Field className={"form-control" + (status && status.titleFrench ? " is-invalid" : "")} id="titleFrench" name="titleFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.titleFrench ? <div className="invalid-feedback">{t(status.titleFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Untertitel")}</legend>

                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="subtitleGerman">{t("Deutsch")}</label>
                                                <Field className={"form-control" + (status && status.subtitleGerman ? " is-invalid" : "")} id="subtitleGerman" name="subtitleGerman"
                                                       required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.subtitleGerman ? <div className="invalid-feedback">{t(status.subtitleGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="subtitleEnglish">{t("Englisch")}</label>
                                                <Field className={"form-control" + (status && status.subtitleEnglish ? " is-invalid" : "")} id="subtitleEnglish" name="subtitleEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.subtitleEnglish ? <div className="invalid-feedback">{t(status.subtitleEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="subtitleFrench">{t("Französisch")}</label>
                                                <Field className={"form-control" + (status && status.subtitleFrench ? " is-invalid" : "")} id="subtitleFrench" name="subtitleFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.subtitleFrench ? <div className="invalid-feedback">{t(status.subtitleFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Beschreibung")}</legend>

                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="descriptionGerman">{t("Deutsch")}</label>
                                                <Field as="textarea" className={"form-control" + (status && status.descriptionGerman ? " is-invalid" : "")} id="descriptionGerman"
                                                       name="descriptionGerman" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.descriptionGerman ? <div className="invalid-feedback">{t(status.descriptionGerman)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="descriptionEnglish">{t("Englisch")}</label>
                                                <Field as="textarea" className={"form-control" + (status && status.descriptionEnglish ? " is-invalid" : "")} id="descriptionEnglish"
                                                       name="descriptionEnglish"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.descriptionEnglish ? <div className="invalid-feedback">{t(status.descriptionEnglish)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="descriptionFrench">{t("Französisch")}</label>
                                                <Field as="textarea" className={"form-control" + (status && status.descriptionFrench ? " is-invalid" : "")} id="descriptionFrench"
                                                       name="descriptionFrench"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status && status.descriptionFrench ? <div className="invalid-feedback">{t(status.descriptionFrench)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Sichbarkeit")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="from">Von</label>
                                                <Field type="date" className={"form-control" + (status && status.from ? " is-invalid" : "")} id="from" name="from"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.from ? <div className="invalid-feedback">{t(status.from)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="to">Bis</label>
                                                <Field type="date" className={"form-control" + (status && status.to ? " is-invalid" : "")} id="to" name="to"/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status && status.to ? <div className="invalid-feedback">{t(status.to)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>Speichern</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    )}
                </Formik>

            </div>

            <Footer/>
        </>
    );
}

export const EDIT_NEWS_PAGE_URL = "/admin/news/news/edit"

export default withRouter(EditNewsPage);
