import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./Nav";
import {gql, useMutation} from "@apollo/client";
import {Form, Formik} from "formik";
import {processFormResult} from "../graphql/type/ErrorExtension";
import {useHistory} from "react-router-dom";
import {REGISTER_CONFIRMATION_PAGE_URL} from "./RegisterConfirmationPage";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const GQ_REGISTER = gql`mutation register(
        $username: String!, 
        $firstname: String!, 
        $lastname: String!, 
        $email: String!, 
        $language: String!, 
        $organisation: String!, 
        $website: String!, 
    ) {
        register(
            username: $username,
            firstname: $firstname,
            lastname: $lastname,
            email: $email,
            language: $language,
            organisation: $organisation,
            website: $website
        ) {
          id
        }
    }`;

interface RegisterInput {
    username: string
    firstname: string
    lastname: string
    email: string
    language: string
    organisation: string
    website?: string
}

function RegisterPage() {

    const {t} = useTranslation("RegisterPage");

    const history = useHistory()

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const [register] = useMutation<{ register: { id: string } }, RegisterInput>(GQ_REGISTER, {
        onCompleted: () => {
            history.push(REGISTER_CONFIRMATION_PAGE_URL, {})
        },
    });

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "RegisterPage"})
    })

    return (<>
        <Header/>

        <Nav/>

        {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

        <div className="container mb-6">
            <div className="col-lg-5 mx-auto">
                <div className="bg-green py-2 mb-3"/>
                <div className="bg-primary px-4 py-3">
                    <h2 className="mb-0 text-uppercase text-white">Registrieren</h2>
                </div>
                <div className="bg-secondary p-4 text-white">

                    <Formik
                        initialValues={{
                            username: '',
                            firstname: '',
                            lastname: '',
                            email: '',
                            language: '',
                            organisation: '',
                            website: ''
                        }}
                        onSubmit={(values, actions) => {
                            actions.setStatus(undefined)
                            setErrors(undefined)

                            register({
                                variables: {
                                    ...values
                                }
                            }).catch(error => {
                                const [e, fe] = processFormResult(error.graphQLErrors)
                                actions.setStatus(fe)
                                setErrors(e)

                            }).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}>
                        {({
                              status,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                          }) => (

                            <Form onSubmit={handleSubmit}>
                                <fieldset className="pb-3">
                                    <div className="form-floating mb-4">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.username ? " is-invalid" : "")} id="username"
                                               placeholder="username" autoComplete="username" required onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="username" className="text-white">{t("Benutzername")}*</label>
                                        {status && status.username ? <div className="invalid-feedback">{t(status.username)}</div> : <></>}
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.firstname ? " is-invalid" : "")} id="firstname"
                                               placeholder="firstname" required onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="firstname" className="text-white">{t("Vorname")}*</label>
                                        {status && status.firstname ? <div className="invalid-feedback">{status.firstname}</div> : <></>}
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.lastname ? " is-invalid" : "")} id="lastname"
                                               placeholder="lastname" required onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="lastname" className="text-white">{t("Nachname")}*</label>
                                        {status && status.lastname ? <div className="invalid-feedback">{status.lastname}</div> : <></>}
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input type="email" className={"form-control bg-transparent text-white border-white" + (status && status.email ? " is-invalid" : "")} id="email"
                                               placeholder="name@example.com" required onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="email" className="text-white">{t("E-Mail")}*</label>
                                        {status && status.email ? <div className="invalid-feedback">{status.email}</div> : <></>}
                                    </div>
                                    <div className="form-floating  mb-4">
                                        <select className={"form-select bg-transparent text-white border-white" + (status && status.language ? " is-invalid" : "")} id="language" required
                                                onChange={handleChange} onBlur={handleBlur}>
                                            <option selected className="text-black">--- {t("Bitte wählen")}</option>
                                            <option value="de_DE" className="text-black">German</option>
                                            <option value="en_UK" className="text-black">English</option>
                                            <option value="fr_FR" className="text-black">Français</option>
                                        </select>
                                        <label htmlFor="language" className="text-white">{t("Sprache")}</label>
                                        {status && status.language ? <div className="invalid-feedback">{status.language}</div> : <></>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.organisation ? " is-invalid" : "")}
                                               id="organisation" placeholder="Workplace" required onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="organisation" className="text-white">{t("Arbeitgeber")}*</label>
                                        {status && status.organisation ? <div className="invalid-feedback">{status.organisation}</div> : <></>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className={"form-control bg-transparent text-white border-white" + (status && status.website ? " is-invalid" : "")} id="website"
                                               placeholder="http://www.company.com" onChange={handleChange} onBlur={handleBlur}/>
                                        <label htmlFor="website" className="text-white">{t("Website Arbeitgeber")}</label>
                                        {status && status.website ? <div className="invalid-feedback">{status.website}</div> : <></>}
                                    </div>
                                </fieldset>
                                <div>
                                    <button type="submit" className="btn btn-light w-100" disabled={isSubmitting}>{t("Registrieren")}</button>
                                    <p className="small mt-3">*{t("Benötigtes Feld")}</p>
                                </div>
                            </Form>

                        )}
                    </Formik>

                </div>
            </div>
        </div>

        <Footer/>
    </>)
}

export const REGISTER_PAGE_URL = "/register"

export default RegisterPage;
