import {Redirect, Route} from 'react-router-dom';
import {LOGIN_PAGE_URL} from "./LoginPage";
import * as H from "history";
import * as React from "react";
import {ExtractRouteParams, RouteChildrenProps, RouteComponentProps} from "react-router";
import {useQuery} from "@apollo/client";
import {GQ_LOGGED_IN, LoggedInResponse} from "../util/auth/auth";


type PrivateRouteProps<Path extends string = string,
    Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>> = {
    location?: H.Location;
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    render?: (props: RouteComponentProps<Params>) => React.ReactNode;
    children?: ((props: RouteChildrenProps<Params>) => React.ReactNode) | React.ReactNode;
    path?: Path | Path[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
};

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> =
    ({
         children: Component,
         ...routeProps
     }) => {

        const {loading, data} = useQuery<LoggedInResponse>(GQ_LOGGED_IN);

        if (loading) <></>

        return (
            <Route {...routeProps} render={(props) =>
                data?.isLoggedIn ? Component : <Redirect to={{pathname: LOGIN_PAGE_URL, state: {fromLocation: props.location}}}/>
            }/>
        );
    };

export default PrivateRoute;