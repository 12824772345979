import React from "react";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./Nav";
import {useMatomo} from "@datapunt/matomo-tracker-react";

function AccessibilityPage() {

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "AccessibilityPage"})
    })

    return (
        <>
            <Header/>

            <Nav/>

            <p>Barrierefreit</p>

            <Footer/>
        </>
    );
}

export const ACCESSIBILITY_PAGE_URL = "/accessibility"

export default AccessibilityPage;
