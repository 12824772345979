import {gql, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Link} from "react-router-dom";
import {ADMIN_HOME_PAGE_URL} from "../AdminHomePage";
import {AnimalAgeGroup} from "../../../graphql/type/AnimalAgeGroup";
import {EDIT_ANIMAL_AGE_GROUP_PAGE_URL} from "./EditAnimalAgeGroupPage";
import {DELETE_ANIMAL_AGE_GROUP_PAGE_URL} from "./DeleteAnimalAgeGroupPage";
import React from "react";
import {useTranslation} from "react-i18next";
import Nav from "../Nav";
import i18n from "i18next";

const GQ = gql`query animalAgeGroupsPage($language: String!) {
    allAnimalAgeGroups {
        id
        caption(language: $language)
        enabled
    }
}`;

interface AnimalAgeGroupsPageRequest {
    language: string;
}

interface AnimalAgeGroupsPageResponse {
    allAnimalAgeGroups: AnimalAgeGroup[]
}

const AnimalAgeGroupsPage = () => {

    const {t} = useTranslation("admin/animalagegroup/AnimalAgeGroupsPage");

    const {loading, error, data} = useQuery<AnimalAgeGroupsPageResponse, AnimalAgeGroupsPageRequest>(GQ, {
        variables: {
            language: i18n.language
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="container mb-5">
                    <Link to={ADMIN_HOME_PAGE_URL}>Zurück</Link>
                </div>

                <div className="container mb-3">
                    <h1>{t("Altersgruppen")}</h1>
                </div>

                <div className="container">
                    <Link to={EDIT_ANIMAL_AGE_GROUP_PAGE_URL}>Neue Altersgruppe</Link>
                </div>

                <table className="table">

                    <thead>
                    <tr>
                        <th>Eintrag</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        // der slice() Aufruf wird benötigt, um das Array zu kopieren, da sonst sortierung nicht möglich ist
                        data?.allAnimalAgeGroups?.slice().sort((i1, i2) => i1.caption!!.localeCompare(i2.caption!!))?.map((it) => (<tr key={it.id}>
                            <td>{it.caption}</td>
                                <td><Link to={{pathname: EDIT_ANIMAL_AGE_GROUP_PAGE_URL, state: {id: it.id}}}>Bearbeiten</Link></td>
                                <td><Link to={{pathname: DELETE_ANIMAL_AGE_GROUP_PAGE_URL, state: {id: it.id}}}>Löschen</Link></td>
                            </tr>))
                    }

                    </tbody>

                </table>

            </div>

            <Footer/>
        </>
    );
}

export const ANIMAL_AGE_GROUPS_PAGE_URL = "/admin/animal_age_groups"

export default AnimalAgeGroupsPage;
