import React from "react";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./Nav";
import {Trans, useTranslation} from "react-i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {gql, useQuery} from "@apollo/client";

const GQ = gql`query imprintPage {
    getConfig {
        id
        emailPublic
    }
}`;

interface ImprintPageResponse {
    getConfig: {
        emailPublic: String
    }
}

function ImprintPage() {

    const {t} = useTranslation("ImprintPage");

    const {loading, error, data} = useQuery<ImprintPageResponse>(GQ);

    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "ImprintPage"})
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="mt-5 mb-4">
                    <h1>{t("Impressum")}</h1>
                </div>

                <p>{t("absatz1")}</p>
                <p><Trans t={t} i18nKey="absatz2"><br/></Trans></p>
                <p><Trans t={t} i18nKey="absatz3" tOptions={
                    {
                        email: data?.getConfig.emailPublic,
                    }
                }><a href={"mailto:" + data?.getConfig.emailPublic}>XXX</a></Trans></p>

                <div className="mt-5 mb-4">
                    <h1>{t("Haftungsausschluss")}</h1>
                </div>

                <div className="mt-5 mb-4">
                    <h2>{t("Haftung für Inhalte")}</h2>
                </div>

                <p>{t("absatz4")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Haftung für Links")}</h2>
                </div>

                <p>{t("absatz5")}</p>

                <div className="mt-5 mb-4">
                    <h2>{t("Urheberrecht")}</h2>
                </div>

                <p>{t("absatz6")}</p>

            </div>

            <Footer/>
        </>
    );
}

export const IMPRINT_PAGE_URL = "/imprint"

export default ImprintPage;
