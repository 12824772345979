import React, {useState} from "react";
import Header from "./Header"
import Footer from "./Footer"
import Nav from "./database/Nav";
import {gql, useMutation, useQuery} from "@apollo/client";
import {AnimalSpecies} from "../graphql/type/AnimalSpecies";
import {AnimalAgeGroup} from "../graphql/type/AnimalAgeGroup";
import {AnimalGender} from "../graphql/type/AnimalGender";
import {AnimalPregnantStatus} from "../graphql/type/AnimalPregnantStatus";
import {AnimalHygieneStatus} from "../graphql/type/AnimalHygieneStatus";
import {IncidentDiscipline} from "../graphql/type/IncidentDiscipline";
import {IncidentContext} from "../graphql/type/IncidentContext";
import {IncidentContributionFactor} from "../graphql/type/IncidentContributionFactor";
import {IncidentDegreeOfDistress} from "../graphql/type/IncidentDegreeOfDistress";
import {IncidentExperimentResultUsability} from "../graphql/type/IncidentExperimentResultUsability";
import {IncidentFrequency} from "../graphql/type/IncidentFrequency";
import {useTranslation} from "react-i18next";
import {processFormResult} from "../graphql/type/ErrorExtension";
import {Form, Formik, Field} from "formik";
import {ReporterType} from "../graphql/type/ReporterType";
import {useHistory} from "react-router-dom";
import {REPORT_INCIDENT_CONFIRMATION_PAGE_URL} from "./ReportIncidentConfirmationPage";
import i18n from "i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const GQ = gql`query reportIncidentPage($language: String!) {
    allEnabledAnimalAgeGroups {
        id
        caption(language: $language)
    }
    allEnabledAnimalGenders {
        id
        caption(language: $language)
    }
    allEnabledAnimalHygieneStatus {
        id
        caption(language: $language)
    }
    allEnabledAnimalPregnantStatus {
        id
        caption(language: $language)
    }
    allEnabledAnimalSpecies {
        id
        caption(language: $language)
    }
    allEnabledIncidentContexts {
        id
        caption(language: $language)
    }
    allEnabledIncidentContributionFactors(language: $language) {
        id
        caption(language: $language)
    }
    allEnabledIncidentDegreeOfDistress {
        id
        caption(language: $language)
    }
    allEnabledIncidentDisciplines(language: $language) {
        id
        caption(language: $language)
    }
    allEnabledIncidentExperimentResultUsabilities {
        id
        caption(language: $language)
    }
    allEnabledIncidentFrequencies {
        id
        caption(language: $language)
    }
    allEnabledReporterTypes {
        id
        caption(language: $language)
    }
}`;

interface ReportIncidentPageRequest {
    language: string
}

interface ReportIncidentPageResponse {
    allEnabledAnimalAgeGroups: AnimalAgeGroup[]
    allEnabledAnimalGenders: AnimalGender[]
    allEnabledAnimalHygieneStatus: AnimalHygieneStatus[]
    allEnabledAnimalPregnantStatus: AnimalPregnantStatus[]
    allEnabledAnimalSpecies: AnimalSpecies[]
    allEnabledIncidentContexts: IncidentContext[]
    allEnabledIncidentContributionFactors: IncidentContributionFactor[]
    allEnabledIncidentDegreeOfDistress: IncidentDegreeOfDistress[]
    allEnabledIncidentDisciplines: IncidentDiscipline[]
    allEnabledIncidentExperimentResultUsabilities: IncidentExperimentResultUsability[]
    allEnabledIncidentFrequencies: IncidentFrequency[]
    allEnabledReporterTypes: ReporterType[]
}

const GQ_REPORT_INCIDENT = gql`mutation reportIncident(
    $title: String!
    $tags: String,
    
    $negativeExperience: String,
    $backgroundInformation: String,
    $reasons: String,
    
    $animalAgeGroup: ID,
    $animalGender: ID,
    $animalHygieneStatus: ID,
    $animalPregnantStatus: ID,
    $animalSpecies: ID,
    
    $incidentContext: ID,
    $incidentContributionFactors: [ID!]!,
    $incidentDegreeOfDistress: ID,
    $incidentDisciplines: [ID!]!
    $incidentExperimentResultUsability: ID,
    $incidentFrequency: ID,
    
    $reporterType: ID,
    
    $periodFrom: Int,
    $periodTo: Int,
    $language: String!
) {
    reportIncident(
        title: $title,
        tags: $tags,
        
        negativeExperience: $negativeExperience,
        backgroundInformation: $backgroundInformation,
        reasons: $reasons,
        
        animalAgeGroup: $animalAgeGroup,
        animalGender: $animalGender,
        animalHygieneStatus: $animalHygieneStatus,
        animalPregnantStatus: $animalPregnantStatus,
        animalSpecies: $animalSpecies,
        
        incidentContext: $incidentContext,
        incidentContributionFactors: $incidentContributionFactors,
        incidentDegreeOfDistress: $incidentDegreeOfDistress,
        incidentDisciplines: $incidentDisciplines,
        incidentExperimentResultUsability: $incidentExperimentResultUsability,
        incidentFrequency: $incidentFrequency,
        
        reporterType: $reporterType,
        
        periodFrom: $periodFrom,
        periodTo: $periodTo,

        language: $language
    ) {
        id
    }
}`;

interface ReportIncidentInput {
    title: string
    tags: string

    negativeExperience: string
    backgroundInformation: string
    reasons: string

    animalAgeGroup: string | undefined
    animalGender: string | undefined
    animalHygieneStatus: string | undefined
    animalPregnantStatus: string | undefined
    animalSpecies: string | undefined

    incidentContext: string | undefined
    incidentContributionFactors: string[]
    incidentDegreeOfDistress: string | undefined
    incidentDisciplines: string[]
    incidentExperimentResultUsability: string | undefined
    incidentFrequency: string | undefined

    reporterType: string | undefined

    periodFrom: number | undefined
    periodTo: number | undefined

    language: string
}

interface ReportIncidentResponse {
    id: string
}

const ReportIncidentPage = () => {

    const gq = useQuery<ReportIncidentPageResponse, ReportIncidentPageRequest>(GQ, {
        variables: {
            language: i18n.language
        }
    });

    const [reportIncident] = useMutation<ReportIncidentResponse, ReportIncidentInput>(GQ_REPORT_INCIDENT, {
        onCompleted: () => {
            history.push(REPORT_INCIDENT_CONFIRMATION_PAGE_URL, {})
        }
    });

    const {t} = useTranslation("ReportIncidentPage");
    const history = useHistory();

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const {trackPageView} = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "ReportIncidentPage"})
    })

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (<>
        <Header/>

        <Nav/>

        {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

        <div className="container mt-5 mb-4">
            <h1>{t("Fallmeldung")}</h1>
        </div>

        <div className="container mb-6">
            <p className="text-primary">{t("Hier können Sie nun einen neuen Fall melden")}</p>
            <p>{t("absatz1")}</p>
            <p className="fw-bold"><span className="highlight">{t("Tipp")}:</span> {t("absatz2")}</p>
        </div>

        <div className="fluid-container">

            <Formik
                initialValues={{
                    title: '',
                    tags: '',
                    backgroundInformation: '',
                    negativeExperience: '',
                    reasons: '',
                    animalAgeGroup: undefined,
                    animalGender: undefined,
                    animalHygieneStatus: undefined,
                    animalPregnantStatus: undefined,
                    animalSpecies: undefined,
                    incidentContext: undefined,
                    incidentContributionFactors: [],
                    incidentDegreeOfDistress: undefined,
                    incidentDisciplines: [],
                    incidentExperimentResultUsability: undefined,
                    incidentFrequency: undefined,
                    periodFrom: undefined,
                    periodTo: undefined,
                    reporterType: undefined
                }}
                onSubmit={(values, actions) => {
                    actions.setStatus(undefined)
                    setErrors(undefined)

                    reportIncident({
                        variables: {
                            ...values,
                            language: i18n.language
                        }
                    }).catch(error => {
                        const [e, fe] = processFormResult(error.graphQLErrors)
                        actions.setStatus(fe)
                        setErrors(e)

                    }).finally(() => {
                        actions.setSubmitting(false);
                    })
                }}>
                {({
                      status,
                      handleSubmit,
                      isSubmitting,
                  }) => (

                    <Form onSubmit={handleSubmit}>
                        {/*Prevent implicit submission of the form*/}
                        <button type="submit" disabled style={{display: "none"}} aria-hidden="true"/>

                        <fieldset className="bg-lightblue">
                            <div className="container">
                                <hr/>
                                <legend>1. {t("Titel")}</legend>
                                <div className="row gx-6">
                                    <div className="col-md">
                                        <div className="mb-3">
                                            <label htmlFor="title">{t("Titel")}*</label>
                                            <Field type="text" className={"form-control" + (status && status.title ? " is-invalid" : "")} id="title" name="title" required/>
                                            <div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>
                                            {status && status.title ? <div className="invalid-feedback">{t(status.title)}</div> : <></>}
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="mb-3">
                                            <label htmlFor="tags">{t("Stichwörter")}</label>
                                            <Field type="text" className={"form-control" + (status && status.tags ? " is-invalid" : "")} id="tags" name="tags"/>
                                            <div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>
                                            {status && status.tags ? <div className="invalid-feedback">{t(status.tags)}</div> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div className="container">
                                <hr/>
                                <legend className="pb-0">2. {t("Tier")}<span> - {t("Falls nicht zutreffend, bitte diesen Block überspringen")}</span></legend>
                                <div className="row g-4">
                                    <div className="col-md">
                                        <div>
                                            <label htmlFor="animalSpecies">{t("Tierart")}</label>
                                            <Field as="select" className={"form-select" + (status && status.animalSpecies ? " is-invalid" : "")} id="animalSpecies" name="animalSpecies">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledAnimalSpecies?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.animalSpecies ? <div className="invalid-feedback">{t(status.animalSpecies)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <label htmlFor="animalAgeGroup">{t("Altersgruppe")}</label>
                                            <Field as="select" className={"form-select" + (status && status.animalAgeGroup ? " is-invalid" : "")} id="animalAgeGroup" name="animalAgeGroup">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledAnimalAgeGroups?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.animalAgeGroup ? <div className="invalid-feedback">{t(status.animalAgeGroup)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <label htmlFor="animalGender">{t("Geschlecht")}</label>
                                            <Field as="select" className={"form-select" + (status && status.animalGender ? " is-invalid" : "")} id="animalGender" name="animalGender">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledAnimalGenders?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.animalGender ? <div className="invalid-feedback">{t(status.animalGender)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <label htmlFor="animalPregnantStatus">{t("Tragend")}</label>
                                            <Field as="select" className={"form-select" + (status && status.animalPregnantStatus ? " is-invalid" : "")} id="animalPregnantStatus"
                                                   name="animalPregnantStatus">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledAnimalPregnantStatus?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.animalPregnantStatus ? <div className="invalid-feedback">{t(status.animalPregnantStatus)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <label htmlFor="animalHygieneStatus">{t("Hygienestatus")}</label>
                                            <Field as="select" className={"form-select" + (status && status.animalHygieneStatus ? " is-invalid" : "")} id="animalHygieneStatus"
                                                   name="animalHygieneStatus">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledAnimalHygieneStatus?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.animalHygieneStatus ? <div className="invalid-feedback">{t(status.animalHygieneStatus)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="bg-lightblue">
                            <div className="container">
                                <hr/>
                                <legend className="pb-0">3. {t("Ereignis")}</legend>
                                <div className="row g-4">
                                    <div className="col-md-4">
                                        <div>
                                            <label htmlFor="incidentDiscipline">{t("Welchem Themengebiet ist das Ereignis zuzuordnen?")}</label>
                                            <Field as="select" className={"form-select" + (status && status.incidentDiscipline ? " is-invalid" : "")} id="incidentDisciplines"
                                                   name="incidentDisciplines" multiple>
                                                {gq.data?.allEnabledIncidentDisciplines?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.incidentDiscipline ? <div className="invalid-feedback">{t(status.incidentDiscipline)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <label htmlFor="incidentContext">{t("In welchem Kontext fand das Ereignis statt?")}</label>
                                            <Field as="select" className={"form-select" + (status && status.incidentContext ? " is-invalid" : "")} id="incidentContext"
                                                   name="incidentContext">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledIncidentContexts?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.incidentContext ? <div className="invalid-feedback">{t(status.incidentContext)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="periodFrom">{t("In welchem Zeitraum haben die Ereignisse stattgefunden?")}</label>
                                            <div className="row g-4">
                                                <div className="col">
                                                    <div className="input-group">
                                                        <div className="input-group-text">{t("von")}</div>
                                                        <Field type="text" className="form-control ps-2" id="periodFrom" name="periodFrom" placeholder={"[" + t("Jahr") + "]"}/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="input-group">
                                                        <div className="input-group-text">{t("bis")}</div>
                                                        <Field type="text" className="form-control ps-2" id="periodTo" name="periodTo" placeholder={"[" + t("Jahr") + "]"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="backgroundInformation" className="form-label">{t("Hintergrundinformationen")}<br/>
                                                {t("Was war das ursprünglich geplante Vorhaben?")}</label>
                                            <Field as="textarea" className="form-control" id="backgroundInformation" name="backgroundInformation" rows={3}
                                                   placeholder={t("Hintergrundinformationen_Platzhalter")}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="negativeExperience" className="form-label">{t("Negativerfahrung –")}<br/>
                                                {t("Was war die Negativerfahrung?")}</label>
                                            <Field as="textarea" className="form-control" id="negativeExperience" name="negativeExperience" rows={3}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="reasons" className="form-label">{t("Worin sehen Sie die Gründe und wie könnte es in Zukunft vermieden werden?")}</label>
                                            <Field as="textarea" className="form-control" id="reasons" name="reasons" rows={3}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="incidentDegreeOfDistress">{t("Einschätzung des Belastungsgrades für das Tier (falls bereits bekannt)")}</label>
                                            <Field as="select" className={"form-select" + (status && status.incidentDegreeOfDistress ? " is-invalid" : "")} id="incidentDegreeOfDistress"
                                                   name="incidentDegreeOfDistress">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledIncidentDegreeOfDistress?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.incidentDegreeOfDistress ? <div className="invalid-feedback">{t(status.incidentDegreeOfDistress)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="incidentExperimentResultUsability">{t("Können die Ergebnisse des Experimentes verwendet werden? (falls bereits bekannt)")}</label>
                                            <Field as="select" className={"form-select" + (status && status.incidentExperimentResultUsability ? " is-invalid" : "")}
                                                   id="incidentExperimentResultUsability" name="incidentExperimentResultUsability">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledIncidentExperimentResultUsabilities?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.incidentExperimentResultUsability ?
                                                    <div className="invalid-feedback">{t(status.incidentExperimentResultUsability)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="incidentFrequency">{t("Wie häufig tritt ein solches Ereignis auf?")}</label>
                                            <Field as="select" className={"form-select" + (status && status.incidentFrequency ? " is-invalid" : "")} id="incidentFrequency"
                                                   name="incidentFrequency">
                                                <option value="">{t("Bitte wählen")}</option>
                                                {gq.data?.allEnabledIncidentFrequencies?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.incidentFrequency ? <div className="invalid-feedback">{t(status.incidentFrequency)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <label
                                                htmlFor="incidentContributionFactors">{t("Welche Faktoren trugen zu dem Ereignis bei? [Mehrfachnennungen möglich, dazu „Strg“ drücken]")}</label>
                                            <Field as="select" className={"form-select" + (status && status.incidentContributionFactors ? " is-invalid" : "")}
                                                   id="incidentContributionFactors" name="incidentContributionFactors" multiple>
                                                {gq.data?.allEnabledIncidentContributionFactors?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                                {status && status.incidentContributionFactors ? <div className="invalid-feedback">{t(status.incidentContributionFactors)}</div> : <></>}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        {/*<fieldset>*/}
                        {/*    <div className="container">*/}
                        {/*        <hr/>*/}
                        {/*        <legend>4. {t("Upload von Materialien")}</legend>*/}
                        {/*        <div className="row gx-6">*/}
                        {/*            <div className="col-md-6">*/}
                        {/*                <div className="mb-3">*/}
                        {/*                    <label htmlFor="formFileMultiple"*/}
                        {/*                           className="form-label">{t("Sie können diese Funktion benutzen, um uns belegende Materialien zukommen zu lassen.")}</label>*/}
                        {/*                    <Field className="form-control" type="file" id="formFileMultiple" name="formFileMultiple" multiple/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</fieldset>*/}

                        <fieldset>
                            {/*<fieldset className="bg-lightblue">*/}
                            <div className="container">
                                <hr/>
                                <legend className="pb-0">4. {t("Melder")}</legend>
                                <div className="row g-4">
                                    <div className="col-md-6">
                                        <label htmlFor="reporterType">{t("Berufsgruppe")}</label>
                                        <Field as="select" className={"form-select" + (status && status.reporterType ? " is-invalid" : "")} id="reporterType" name="reporterType">
                                            <option value="">{t("Bitte wählen")}</option>
                                            {gq.data?.allEnabledReporterTypes?.map((it) => (<option key={it.id} value={it.id}>{it.caption}</option>))}
                                            {status && status.reporterType ? <div className="invalid-feedback">{t(status.reporterType)}</div> : <></>}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div className="pb-5">
                            <div className="container">
                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>{t("Fall melden")}</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>

        <Footer/>
    </>);
}

export const REPORT_INCIDENT_PAGE_URL = "/report_incident"

export default ReportIncidentPage;
