import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Person} from "../../../graphql/type/Person";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {Field, Form, Formik} from 'formik';
import Nav from "../Nav";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import {useTranslation} from "react-i18next";
import {PERSONS_PAGE_URL} from "./PersonsPage";
import * as Yup from 'yup';
import {getQueryParam} from "../../../util/queryparam";

interface EditPersonPageProps {
    id: string
}

const GQ = gql`query editPersonPage($id: ID!) {
    getPerson(id: $id) {
        id
        firstname
        lastname
        displayName
        organisation
        website
        language
        email
        admin
        user {
            username
            enabled
        }
    }
}`;

interface EditPersonPageRequest {
    id: string;
}

interface EditPersonPageResponse {
    getPerson: Person
}

const GQ_UPDATE_PERSON = gql`mutation updatePerson(
    $id: ID!,
    $firstname: String!,
    $lastname: String!,
    $email: String!,
    $language: String!,
    $displayName: String,
    $organisation: String!,
    $website: String,
    $admin: Boolean!,
    $username: String!,
    $password: String,
    $enabled: Boolean!
) {
    updatePerson(
        id: $id,
        firstname: $firstname,
        lastname: $lastname,
        email: $email,
        language: $language,
        displayName: $displayName,
        organisation: $organisation,
        website: $website,
        admin: $admin,
        username: $username,
        password: $password,
        enabled: $enabled
    ) {
        id
    }
}`;

const EditPersonPage: React.FC<RouteComponentProps<{}, {}, EditPersonPageProps>> = (props) => {
    const id = props.location.state?.id ? props.location.state?.id : getQueryParam("id")!!

    const {t} = useTranslation("admin/person/EditPersonPage");

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<EditPersonPageResponse, EditPersonPageRequest>(GQ, {
        variables: {
            id: id
        },
        skip: id == null
    });

    const [gqUpdatePerson] = useMutation(GQ_UPDATE_PERSON, {
        onCompleted: () => {
            history.push(PERSONS_PAGE_URL)
        },
        // refetchQueries: [
        //     {
        //         query: gql`query allPerson {
        //             allPerson {
        //                 id
        //                 caption
        //                 captionGerman
        //                 captionEnglish
        //                 captionFrench
        //                 enabled
        //             }
        //         }`
        //     }
        // ]
    });

    const history = useHistory()

    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={PERSONS_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Person")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        firstname: gq.data?.getPerson?.firstname,
                        lastname: gq.data?.getPerson?.lastname,
                        email: gq.data?.getPerson?.email,
                        displayName: gq.data?.getPerson?.displayName,
                        language: gq.data?.getPerson?.language,
                        organisation: gq.data?.getPerson?.organisation,
                        website: gq.data?.getPerson?.website,
                        admin: gq.data?.getPerson?.admin,
                        username: gq.data?.getPerson?.user?.username,
                        password: undefined,
                        passwordConfirmation: undefined,
                        enabled: gq.data?.getPerson?.user?.enabled,
                    }}
                    validationSchema={
                        Yup.object().shape({
                            passwordConfirmation: Yup.string()
                                .when("password", {
                                    is: (value: string) => !!(value && value.length > 0),
                                    then: Yup.string().oneOf([Yup.ref("password")], "Passwort must match")
                                })
                        })
                    }
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqUpdatePerson({
                            variables: {
                                id: id,
                                ...values,
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)

                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          errors,
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Name")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="firstname">{t("Vorname")}*</label>
                                                <Field type="text" className={"form-control" + (status?.firstname ? " is-invalid" : "")} id="firstname" name="firstname" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status?.firstname ? <div className="invalid-feedback">{t(status.firstname)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="lastname">{t("Nachname")}*</label>
                                                <Field type="text" className={"form-control" + (status?.lastname ? " is-invalid" : "")} id="lastname" name="lastname" required/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status?.lastname ? <div className="invalid-feedback">{t(status.lastname)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="displayName">{t("Anzeigename")}</label>
                                                <Field type="text" className={"form-control" + (status?.displayName ? " is-invalid" : "")} id="displayName" name="displayName"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status?.displayName ? <div className="invalid-feedback">{t(status.displayName)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Weitere Informationen")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="email">{t("E-Mail")}*</label>
                                                <Field type="text" className={"form-control" + (status?.email ? " is-invalid" : "")} id="email" name="email" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status?.email ? <div className="invalid-feedback">{t(status.email)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="language">{t("Sprache")}*</label>
                                                <Field type="text" className={"form-control" + (status?.language ? " is-invalid" : "")} id="language" name="language" required/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status?.language ? <div className="invalid-feedback">{t(status.language)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Organisation")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="organisation">{t("Organisation")}*</label>
                                                <Field type="text" className={"form-control" + (status?.organisation ? " is-invalid" : "")} id="organisation" name="organisation" required/>
                                                {/*<div className="form-text">{t("Bitte vergeben Sie einen passenden Titel für den gemeldeten Fall")}</div>*/}
                                                {status?.organisation ? <div className="invalid-feedback">{t(status.organisation)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="website">{t("Webseite")}</label>
                                                <Field type="text" className={"form-control" + (status?.website || errors?.website ? " is-invalid" : "")} id="website" name="website"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status?.website ? <div className="invalid-feedback">{t(status.website)}</div> : <></>}
                                                {errors && errors.website ? <div className="invalid-feedback">{t(errors.website)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div className="container">
                                    <hr/>
                                    <legend>{t("Konto")}</legend>
                                    <div className="row gx-6">
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="username">{t("Benutzername")}*</label>
                                                <Field type="text" className={"form-control" + (status?.username ? " is-invalid" : "")} id="username" name="username" required/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status?.username ? <div className="invalid-feedback">{t(status.username)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <Field type="checkbox" className={"" + (status?.enabled ? " is-invalid" : "")} id="enabled" name="enabled"/>
                                                <label htmlFor="enabled">Aktiviert</label>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <Field type="checkbox" className={"" + (status?.admin ? " is-invalid" : "")} id="admin" name="admin"/>
                                                <label htmlFor="admin">Administrator</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-6">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="password">{t("Passwort")}</label>
                                                <Field type="password" className={"form-control" + (status?.password || errors?.password ? " is-invalid" : "")} id="password" name="password"/>
                                                {/*<div className="form-text">{t("Geben Sie bitte ein paar Stichworte ein")}</div>*/}
                                                {status?.password ? <div className="invalid-feedback">{t(status.password)}</div> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-3">
                                                <label htmlFor="passwordConfirmation">{t("Passwort bestätigen")}</label>
                                                <Field type="password" className={"form-control" + (status?.passwordConfirmation || errors?.passwordConfirmation ? " is-invalid" : "")}
                                                       id="passwordConfirmation" name="passwordConfirmation"/>
                                                {status?.passwordConfirmation ? <div className="invalid-feedback">{t(status.passwordConfirmation)}</div> : <></>}
                                                {errors?.passwordConfirmation ? <div className="invalid-feedback">{t(errors.passwordConfirmation)}</div> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-success" disabled={isSubmitting}>Speichern</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>

            <Footer/>
        </>
    );
}

export const EDIT_PERSON_PAGE_URL = "/admin/persons/person/edit"

export default withRouter(EditPersonPage);
