import {gql, useMutation, useQuery} from "@apollo/client";
import Header from "../../Header";
import Footer from "../../Footer";
import {Link, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useState} from "react";
import Nav from "../Nav";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {processFormResult} from "../../../graphql/type/ErrorExtension";
import {Incident} from "../../../graphql/type/Incident";
import {INCIDENTS_PAGE_URL} from "./IncidentsPage";
import i18n from "i18next";

const GQ = gql`query deleteIncidentPage($id: ID!, $language: String!) {
    getIncident(id: $id) {
        id
        title(language: $language)
    }
}`;

interface DeleteIncidentPageRequest {
    id: string
    language: string
}

interface DeleteIncidentPageResponse {
    getIncident: Incident
}

const GQ_DELETE = gql`mutation deleteIncident($id: ID!) {
    deleteIncident(id: $id)
}`;

interface DeleteIncidentRequest {
    id: string;
}

interface DeleteIncidentResponse {
}

interface DeleteIncidentPageProps {
    id: string
}

const DeleteIncidentPage: React.FC<RouteComponentProps<{}, {}, DeleteIncidentPageProps>> = (props) => {

    const {t} = useTranslation("admin/incident/DeleteIncidentPage")

    const history = useHistory();

    const [errors, setErrors] = useState<string[] | undefined>(undefined)

    const gq = useQuery<DeleteIncidentPageResponse, DeleteIncidentPageRequest>(GQ, {
        variables: {
            id: props.location.state.id,
            language: i18n.language
        }
    });

    const [gqDelete] = useMutation<DeleteIncidentResponse, DeleteIncidentRequest>(GQ_DELETE, {
        onCompleted: () => {
            history.push(INCIDENTS_PAGE_URL);
        },
        refetchQueries: [
            {
                query: gql`query incidentsPage {
                    allIncidents {
                        id
                    }
                }`
            },
            {
                query: gql`query incidentsPage {
                    unlockedIncidents {
                        id
                    }
                }`
            }
        ]
    });


    if (gq.loading) return <p>Loading...</p>;
    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            {errors ? <p>{JSON.stringify(errors)}</p> : <></>}

            <div className="container mb-5">
                <Link to={INCIDENTS_PAGE_URL}>Zurück</Link>
            </div>

            <div className="container mb-3">
                <h1>{t("Fall löschen")}</h1>
            </div>

            <div className="fluid-container">

                <Formik
                    initialValues={{
                        replaceWith: undefined
                    }}
                    onSubmit={(values, actions) => {
                        actions.setStatus(undefined)
                        setErrors(undefined)

                        gqDelete({
                            variables: {
                                id: props.location.state.id
                            }
                        }).catch(error => {
                            const [e, fe] = processFormResult(error.graphQLErrors)
                            actions.setStatus(fe)
                            setErrors(e)
                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    {({
                          status,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <p>Soll der Fall <em>{gq.data?.getIncident?.title}</em> wirklich gelöscht werden?</p>
                                </div>
                            </fieldset>

                            <fieldset className="bg-lightblue">
                                <div className="container">
                                    <div className="pb-3 pt-3">
                                        <button type="submit" className="btn btn-warning" disabled={isSubmitting}>Löschen</button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )}
                </Formik>

            </div>

            <Footer/>
        </>
    );
}

export const DELETE_INCIDENT_PAGE_URL = "/admin/incidents/incident/delete"

export default withRouter(DeleteIncidentPage);
