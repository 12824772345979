import React from "react";
import Header from "./../Header"
import Footer from "./../Footer"
import {Link} from "react-router-dom";
import {NEWS_LIST_PAGE_URL} from "./news/NewsListPage";
import Nav from "./Nav";
import {ANIMAL_AGE_GROUPS_PAGE_URL} from "./animalagegroup/AnimalAgeGroupsPage";
import {useTranslation} from "react-i18next";
import {ANIMAL_GENDERS_PAGE_URL} from "./animalgender/AnimalGendersPage";
import {ANIMAL_HYGIENE_STATUS_PAGE_URL} from "./animalhygienestatus/AnimalHygieneStatusPage";
import {ANIMAL_SPECIES_PAGE_URL} from "./animalspecies/AnimalSpeciesPage";
import {ANIMAL_PREGNANT_STATUS_PAGE_URL} from "./animalpregnantstatus/AnimalPregnantStatusPage";
import {INCIDENT_CONTEXTS_PAGE_URL} from "./incidentcontext/IncidentContextsPage";
import {INCIDENT_DISCIPLINES_PAGE_URL} from "./incidentdiscipline/IncidentDisciplinesPage";
import {INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL} from "./incidentdegreeofdistress/IncidentDegreeOfDistressPage";
import {INCIDENT_EXPERIMENT_RESULT_USABILITIES_PAGE_URL} from "./incidentexperimentusability/IncidentExperimentResultUsabilitiesPage";
import {INCIDENT_FREQUENCIES_PAGE_URL} from "./incidentfrequency/IncidentFrequenciesPage";
import {INCIDENT_CONTRIBUTION_FACTORS_PAGE_URL} from "./incidentcontributionfactor/IncidentContributionFactorsPage";
import {REPORTER_TYPES_PAGE_URL} from "./reportertype/ReporterTypesPage";
import {INCIDENTS_PAGE_URL} from "./incident/IncidentsPage";
import {PERSONS_PAGE_URL} from "./person/PersonsPage";

const AdminHomePage = () => {

    const {t} = useTranslation("admin/AdminHomePage");

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="container mb-3">
                    <h1>{t("Administration")}</h1>
                </div>

                <ul>
                    <li><Link to={ANIMAL_AGE_GROUPS_PAGE_URL}>Altersgruppen</Link></li>
                    <li><Link to={INCIDENT_DEGREE_OF_DISTRESS_PAGE_URL}>Belastungsgrade</Link></li>
                    <li><Link to={PERSONS_PAGE_URL}>Benutzer</Link></li>
                    <li><Link to={REPORTER_TYPES_PAGE_URL}>Berufsgruppen</Link></li>
                    <li><Link to={INCIDENT_CONTRIBUTION_FACTORS_PAGE_URL}>Faktoren</Link></li>
                    <li><Link to={INCIDENTS_PAGE_URL}>Fälle</Link></li>
                    <li><Link to={ANIMAL_GENDERS_PAGE_URL}>Geschlechter</Link></li>
                    <li><Link to={INCIDENT_FREQUENCIES_PAGE_URL}>Häufigkeiten</Link></li>
                    <li><Link to={ANIMAL_HYGIENE_STATUS_PAGE_URL}>Hygienestatus</Link></li>
                    <li><Link to={INCIDENT_CONTEXTS_PAGE_URL}>Kontexte</Link></li>
                    <li><Link to={NEWS_LIST_PAGE_URL}>News</Link></li>
                    <li><Link to={ANIMAL_PREGNANT_STATUS_PAGE_URL}>Status</Link></li>
                    <li><Link to={INCIDENT_DISCIPLINES_PAGE_URL}>Themengebiete</Link></li>
                    <li><Link to={ANIMAL_SPECIES_PAGE_URL}>Tierarten</Link></li>
                    <li><Link to={INCIDENT_EXPERIMENT_RESULT_USABILITIES_PAGE_URL}>Verwertbarkeiten</Link></li>
                </ul>

            </div>

            <Footer/>
        </>
    );
}

export const ADMIN_HOME_PAGE_URL = "/admin/home"

export default AdminHomePage;
