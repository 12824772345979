import React, {useState} from "react";
import Header from "./../Header"
import Footer from "./../Footer"
import Nav from "./Nav";
import {gql, useQuery} from "@apollo/client";
import {Incident} from "../../graphql/type/Incident";
import {Link} from "react-router-dom";
import {INCIDENT_PAGE_URL} from "./IncidentPage";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const GQ = gql`query incidentsPage($language: String!, $searchKey: String) {
    unlockedIncidents(language: $language, searchKey: $searchKey) {
        id
        title(language: $language)
        incidentDisciplines {
            caption(language: $language)
        }
        tags(language: $language)
    }
}`

interface IncidentsPageRequest {
    language: string
    searchKey?: string
}

interface IncidentsPageResponse {
    unlockedIncidents: Incident[]
}

const IncidentsPage = () => {

    const {t} = useTranslation("database/IncidentsPage");

    const [searchKey, setSearchKey] = useState<string>("")

    const gq = useQuery<IncidentsPageResponse, IncidentsPageRequest>(GQ, {
        variables: {
            language: i18n.language,
            ...(searchKey !== "" ? {searchKey: searchKey} : {})
        }
    });

    const {trackPageView} = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "IncidentsPage"})
    })

    if (gq.error) return <p>Error :(</p>;

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container mt-5 mb-4">
                <h1>{t("Alle Fallmeldungen")}</h1>
            </div>

            {/*<div className="container mb-6">*/}
            {/*    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur*/}
            {/*        ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,*/}
            {/*        arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.*/}
            {/*        Aenean vulputate eleifend tellus.</p>*/}

            <div className="fluid-container bg-lightblue py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {/*    <div className="row mb-3">*/}
                            {/*<label htmlFor="inputEntries" className="col-sm-auto col-form-label">Show entries</label>*/}
                            {/*<div className="col-sm-auto">*/}
                            {/*    <select className="form-select" id="inputEntries">*/}
                            {/*        <option selected>10</option>*/}
                            {/*        <option value="1">20</option>*/}
                            {/*        <option value="2">50</option>*/}
                            {/*        <option value="3">100</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-md-6">
                            <div className="row g-3 justify-content-end">
                                <div className="col-auto">
                                    <label htmlFor="inputSearch" className="col-form-label">Suche</label>
                                </div>
                                <div className="col-auto">
                                    <input type="text" id="inputSearch" className="form-control" onChange={(e) => {
                                        setSearchKey(e.target.value)
                                    }} placeholder={t("Titel, Kategorien, Stichwörter")}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Erweiterte Suche [START]*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-md-12">*/}
                    {/*        <p className="text-end">*/}
                    {/*            <a className="btn btn-outline-primary btn-sm" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">*/}
                    {/*                <i className="bi bi-filter me-2"/>Erweiterte Suche*/}
                    {/*            </a>*/}
                    {/*        </p>*/}
                    {/*        <div className="collapse" id="collapseExample">*/}
                    {/*            <div className="card card-body mb-5">*/}
                    {/*                <form>*/}
                    {/*                    <fieldset>*/}
                    {/*                        <div className="container">*/}
                    {/*                            <legend className="pb-0">Tier</legend>*/}
                    {/*                            <div className="row g-4">*/}
                    {/*                                <div className="col-md">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="TierartSelect">Tierart</label>*/}
                    {/*                                        <select className="form-select" id="TierartSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="AltersgruppeSelect">Altersgruppe</label>*/}
                    {/*                                        <select className="form-select" id="AltersgruppeSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="GeschlechtSelect">Geschlecht</label>*/}
                    {/*                                        <select className="form-select" id="GeschlechtSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}

                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="TragendSelect">Tragend?</label>*/}
                    {/*                                        <select className="form-select" id="TragendSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="HygienestatusSelect">Hygienestatus</label>*/}
                    {/*                                        <select className="form-select" id="HygienestatusSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </fieldset>*/}
                    {/*                    <fieldset>*/}
                    {/*                        <div className="container">*/}
                    {/*                            <legend className="pb-0">Ereignis</legend>*/}
                    {/*                            <div className="row g-4">*/}
                    {/*                                <div className="col-md-3">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="ThemengebietSelect">Themengebiet</label>*/}
                    {/*                                        <select className="form-select" id="ThemengebietSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-3">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="EreignisSelect">Ereignisart</label>*/}
                    {/*                                        <select className="form-select" id="EreignisSelect">*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-3">*/}
                    {/*                                    <div className="mb-3">*/}
                    {/*                                        <label htmlFor="EreignisSelect">Zeitraum</label>*/}
                    {/*                                        <div className="row g-4">*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                <div className="input-group">*/}
                    {/*                                                    <div className="input-group-text">von</div>*/}
                    {/*                                                    <input type="text" className="form-control ps-2" id="titelInput" placeholder="[Jahr]"/>*/}
                    {/*                                                </div>*/}
                    {/*                                            </div>*/}
                    {/*                                            <div className="col">*/}
                    {/*                                                <div className="input-group">*/}
                    {/*                                                    <div className="input-group-text">bis</div>*/}
                    {/*                                                    <input type="text" className="form-control ps-2" id="titelInput" placeholder="[Jahr]"/>*/}
                    {/*                                                </div>*/}
                    {/*                                            </div>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-3">*/}
                    {/*                                    <div>*/}
                    {/*                                        <label htmlFor="EreignisSelect">Faktoren</label>*/}
                    {/*                                        <select className="form-select" id="EreignisSelect" multiple>*/}
                    {/*                                            <option selected>Bitte wählen</option>*/}
                    {/*                                            <option value="1">One</option>*/}
                    {/*                                            <option value="2">Two</option>*/}
                    {/*                                            <option value="3">Three</option>*/}
                    {/*                                            <option value="4">Four</option>*/}
                    {/*                                            <option value="5">Five</option>*/}
                    {/*                                        </select>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </fieldset>*/}
                    {/*                    <div className="pb-5">*/}
                    {/*                        <div className="container">*/}
                    {/*                            <div className="col-12">*/}
                    {/*                                <button className="btn btn-primary" type="submit">Filter anwenden</button>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}


                    {/*                </form>*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*Erweiterte Suche [ENDE]*/}

                    {/*Tabelle*/}
                    <div className="table-responsive mt-3">
                        <table className="table table-hover">
                            <thead className="thead bg-primary">
                            <tr>
                                <th scope="col">{t("Titel")}</th>
                                <th scope="col">{t("Kategorie")}</th>
                                <th scope="col">{t("Stichwörter")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {gq.loading ? <p>Loading...</p> : <>{gq.data?.unlockedIncidents?.map((it) => (
                                <tr key={it.id}>
                                    <th scope="row"><Link to={{pathname: INCIDENT_PAGE_URL, state: {id: it.id}}}>{it.title}</Link></th>
                                    <td>{it.incidentDisciplines?.map((it) => it.caption)?.join("; ")}</td>
                                    <td>{it.tags}</td>
                                </tr>
                            ))}</>}
                            </tbody>
                        </table>
                    </div>
                    {/*<div className="row mt-3">*/}
                    {/*    <div className="col">*/}
                    {/*        Showing 1 to 4 entries [filtered from 27 total entries]*/}
                    {/*    </div>*/}
                    {/*    <div className="col">*/}
                    {/*        <nav aria-label="Page navigation">*/}
                    {/*            <ul className="pagination justify-content-end">*/}
                    {/*                <li className="page-item disabled">*/}
                    {/*                    <a className="page-link" href="#" aria-label="Previous">*/}
                    {/*                        <span aria-hidden="true">&laquo; previous</span>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li className="page-item active"><a className="page-link" href="#">1</a></li>*/}
                    {/*                <li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                    {/*                <li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                    {/*                <li className="page-item">*/}
                    {/*                    <a className="page-link" href="#" aria-label="Next">*/}
                    {/*                        <span aria-hidden="true">next &raquo;</span>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </nav>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <Footer/>
        </>
    );
}

export const INCIDENTS_PAGE_URL = "/database/incidents"

export default IncidentsPage;
