import React from "react";
import Header from "./../../Header"
import Footer from "./../../Footer"
import Nav from "../Nav";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {gql, useQuery} from "@apollo/client";
import {RouteComponentProps, withRouter} from "react-router-dom";
import i18n from "i18next";

interface NewsPageProps {
    id: string
}

const GQ = gql`query NewsPage($id: ID!, $language: String!) {
    getNews(id: $id) {
        id
        title(language: $language)
        subtitle(language: $language)
        description(language: $language)
    }
}`;

interface NewsPageRequest {
    id: string
    language: string
}

interface NewsPageResponse {
    getNews: {
        id: string
        title: string
        subtitle: string
        description: string
    }
}

const NewsPage: React.FC<RouteComponentProps<{}, {}, NewsPageProps>> = (props) => {

    // const {t} = useTranslation("project/news/NewsPage");

    const gq = useQuery<NewsPageResponse, NewsPageRequest>(GQ, {
        variables: {
            id: props.location.state?.id,
            language: i18n.language
        },
        skip: props.location.state?.id == null
    });

    const {trackPageView} = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView({documentTitle: "EventPage"})
    })

    return (
        <>
            <Header/>

            <Nav/>

            <div className="container">

                <div className="mt-5 mb-4">
                    <h1>{gq.data?.getNews.title}</h1>
                </div>

                <h2>{gq.data?.getNews.subtitle}</h2>

                <p dangerouslySetInnerHTML={{__html: gq.data?.getNews.description!}}/>

            </div>

            <Footer/>
        </>
    );
}

export const NEWS_PAGE_URL = "/project/news/entry"

export default withRouter(NewsPage);
